import { generatePath } from "react-router-dom";

import {
  MainRoutes,
  SignUpInvitedAccountRoutes,
  SignUpRoutes,
  SignUpTrialAccountRoutes,
} from "@router/route-params";

/** Name of the different paths in the app  */
export enum EAppRoutes {
  LoginRoute = "LoginRoute",
  SignupTrialRoute = "SignupTrialRoute",
  ResendEmail = "ResendEmail",
  SignupStartFreeTrial = "SignupStartFreeTrial",
  CreateTrialWorkspace = "CreateTrialWorkspace",
  SignupInvitedRoute = "SignupInvitedRoute",
}

/** List of routes available in the app */
export const AppRoutes: Record<EAppRoutes, string> = {
  /** The route of login with dynamic segments */
  [EAppRoutes.LoginRoute]: `${MainRoutes.root}/${MainRoutes.login}`,

  /** The route of signup try account */
  // eslint-disable-next-line max-len
  [EAppRoutes.SignupTrialRoute]: `${MainRoutes.root}/${MainRoutes.signup}/${SignUpRoutes.trial}/${SignUpTrialAccountRoutes.createAccount}`,

  /** The route of signup invited */
  // eslint-disable-next-line max-len
  [EAppRoutes.SignupInvitedRoute]: `${MainRoutes.root}/${MainRoutes.signup}/${SignUpRoutes.invited}/${SignUpInvitedAccountRoutes.createAccount}`,

  /** The route start free trial */
  [EAppRoutes.SignupStartFreeTrial]: `${MainRoutes.root}/${MainRoutes.signup}/${SignUpRoutes.trial}`,

  /** The resend verification email route */
  [EAppRoutes.ResendEmail]: `${MainRoutes.root}/${MainRoutes.resendEmail}`,

  /** The route trial create workspace */
  // eslint-disable-next-line max-len
  [EAppRoutes.CreateTrialWorkspace]: `${MainRoutes.root}/${MainRoutes.signup}/${SignUpRoutes.trial}/${SignUpTrialAccountRoutes.createWorkspace}`,
};

/**
 * Generates the login route
 */
export function generateLoginRoute(): string {
  return generatePath(AppRoutes.LoginRoute);
}

/**
 * Generates the trial signup route
 */
export function generateTrialSignupRoute(): string {
  return generatePath(AppRoutes.SignupTrialRoute);
}

/**
 * Generates the invited sign up route
 */
export function generateInvitedSignupRoute(): string {
  return generatePath(AppRoutes.SignupInvitedRoute);
}

/**
 * Generates the signup route
 */
export function generateSignupStartFreeTrial(): string {
  return generatePath(AppRoutes.SignupStartFreeTrial);
}

/**
 * Generates the resend verification email route
 */
export function generateResendEmailRoute(): string {
  return generatePath(AppRoutes.ResendEmail);
}

/**
 * Generates the create trial workspace route
 */
export function generateTrialCreateWorkspace(): string {
  return generatePath(AppRoutes.CreateTrialWorkspace);
}
