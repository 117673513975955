import { ApiClient } from "@stellar/api-logic";

/** Returns api client created from the provided api url */
export function getApiClient(apiUrl: string): ApiClient {
  return new ApiClient({
    url: apiUrl,
    clientOptions: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- this key is defined by the backend
        "X-Holobuilder-Component": "sphereEntryPage",
      },
    },
  });
}
