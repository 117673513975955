import { useEffect, useMemo, useState } from "react";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { UlmContainer } from "@components/ulm/ulm-container";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { colors } from "@styles/common-colors";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { UlmButton } from "@components/ulm/ulm-button";
import { AcknowledgementText } from "@components/common/acknowledgement-text";
import { runtimeConfig } from "@src/runtime-config";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import { SelectServer } from "@pages/ulm/login/select-server";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import {
  PageIdentifier,
  SSOProviders,
} from "@components/ulm/SSO-providers/sso-providers";
import { serverOptions } from "@constants/ulm-constants";
import { useOutletContext } from "react-router-dom";
import { CreateAccountContext } from "@pages/ulm/signup/trial-account/create-trial-accounts-types";
import { useToast, QuestionMarkInCircleIcon } from "@faro-lotv/flat-ui";
import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { isValidEmail } from "@utils/email-utils";
import { MainRoutes } from "@router/route-params";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useAppSelector } from "@store/store-helper";
import { redirectWithSSOProvider } from "@components/ulm/SSO-providers/SSO-providers-utils";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import { isFaroEmail } from "@pages/ulm/signup/create-account-utils";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

/** Create trial account page where we will validate if is a new email or not */
export function CreateTrialAccount(): JSX.Element {
  const { navigateToLogin, navigateToTrialSignup } = useAppNavigation();
  const { openToast } = useToast();
  const coreApiClient = useCoreApiClient();
  const client = useAppSelector(ulmConfigClientSelector);
  const { handleError } = useErrorHandlerContext();

  const [isValidEmailFormat, setIsValidEmailFormat] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { accountInfo, updateAccountInfo, isSmallHeightScreen, trialType } =
    useOutletContext<CreateAccountContext>();

  const shouldShowSSOProviders = useMemo(
    () =>
      runtimeConfig.oauth.providers.length > 0 &&
      runtimeConfig.features.hasCompanySSO,
    []
  );

  /** Triggered when on the continue button */
  async function onClickContinueButton(): Promise<void> {
    // If the email format is invalid set error to true and do an early exit
    if (!isValidEmail(accountInfo.email)) {
      setEmailError("Please provide a valid email");
      return;
    }

    setIsLoading(true);

    try {
      // Validate that the email address can be used to create a trial account
      await coreApiClient.V1.SDB.validateEmail({
        email: accountInfo.email,
        trialType: trialType ?? "",
      });

      // Check for existing account in the same server before proceed
      const { servers } = await coreApiClient.V3.SDB.getUserCheck(
        accountInfo.email
      );

      const isAccountAlreadyExist = servers.find(
        (server) =>
          server.identifier === accountInfo.selectedServer &&
          server.status === SphereDashboardAPITypes.EServerStatus.userFound
      );

      if (isAccountAlreadyExist) {
        setEmailError("An account already exists with the same email");
        setIsValidEmailFormat(false);
      } else {
        if (isFaroEmail(accountInfo.email)) {
          openToast({
            title: "You are being redirected...",
            variant: "neutral",
          });

          redirectWithSSOProvider(
            CoreAPITypes.EOAuthProvider.faroSphere,
            client,
            accountInfo.selectedServer
          );

          setIsLoading(false);

          return;
        }

        updateAccountInfo({
          selectedServer: accountInfo.selectedServer,
          previousLocationRef: `/${MainRoutes.signup}`,
        });
        navigateToTrialSignup();
      }
    } catch (error) {
      handleError({
        id: `createTrial-validateEmail-${Date.now().toString()}`,
        title: "Failed to check the email",
        error,
        uiType: "toast",
      });
    }

    setIsLoading(false);
  }

  /** update server selected */
  function handleSelectServer(
    server: SphereDashboardAPITypes.EServerIdentifier
  ): void {
    updateAccountInfo({ selectedServer: server });
  }

  useEffect(() => {
    /** Handle email error  */
    if (isValidEmail(accountInfo.email)) {
      setIsValidEmailFormat(true);
      setEmailError("");
    } else if (!accountInfo.email) {
      setIsValidEmailFormat(false);
      setEmailError("");
    } else {
      // Set email error for invalid email
      setIsValidEmailFormat(false);
      setEmailError("Please provide a valid email");
    }
  }, [accountInfo.email]);

  return (
    <UlmContainer>
      <Box
        paddingTop={isSmallHeightScreen ? "40px" : "100px"}
        sx={{
          color: colors.gray850,
          fontSize: "32px",
          lineHeight: "44px",
          fontWeight: 600,
          letterSpacing: "-0.32px",
        }}
      >
        Start free trial
      </Box>

      {/* Already a member */}
      <Box
        sx={{
          paddingTop: "10px",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0px",
          color: colors.gray700,
          display: "flex",
          alignItems: "center",
        }}
      >
        Already a member?
        <Link
          component="button"
          underline="none"
          onClick={navigateToLogin}
          sx={{
            marginLeft: "5px",
            color: colors.blue500,
          }}
        >
          Log in
        </Link>
      </Box>

      {/* Servers */}
      <Box
        sx={{
          width: "100%",
          paddingTop: "40px",
        }}
      >
        <SelectServer
          activeServers={serverOptions}
          selectedServer={accountInfo.selectedServer}
          onSelectServer={handleSelectServer}
          helpComponent={
            <SphereTooltip
              title={
                <>
                  <Typography
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    paddingBottom={"10px"}
                  >
                    Hosted Region
                  </Typography>
                  <Typography fontSize={"12px"} fontWeight={400}>
                    The Sphere XG Cloud is hosted on servers within either
                    Europe or North America. Choose the server depending on your
                    location and IP address.
                  </Typography>
                </>
              }
              shouldSkipWrapper
              tooltipProps={{
                PopperProps: {
                  sx: {
                    maxWidth: "215px",
                  },
                },
              }}
            >
              <Box>
                <QuestionMarkInCircleIcon sx={{ fontSize: "16px" }} />
              </Box>
            </SphereTooltip>
          }
        />
      </Box>

      {/* Email input */}
      <Stack
        sx={{
          width: "100%",
        }}
      >
        <FaroInputLabel title="Email" />
        <UlmTextField
          value={accountInfo.email}
          type="email"
          name="email"
          autoComplete="email"
          isValidValue={isValidEmailFormat}
          isError={!!emailError}
          errorText={emailError}
          placeholder="name@company.com"
          onChange={(event) =>
            updateAccountInfo({ email: event.target.value.trim() })
          }
        />
      </Stack>

      {/* Button */}
      <UlmButton
        buttonText="Continue"
        isDisabled={!accountInfo.email}
        onClick={onClickContinueButton}
        isLoading={isLoading}
        isLoadingText="Confirming"
        sx={{
          marginTop: "8px",
        }}
      />

      {shouldShowSSOProviders && (
        <>
          <Divider sx={{ width: "100%", mt: "32px" }}>
            <Typography sx={{ color: colors.gray600 }}>
              or sign up with
            </Typography>
          </Divider>

          <SSOProviders pageIdentifier={PageIdentifier.trialAccount} />
        </>
      )}

      {/* Terms of use and privacy policy text */}
      <AcknowledgementText />
    </UlmContainer>
  );
}
