/* eslint-disable react/prefer-es6-class -- Error boundary is not available in functional component */
import { HandledError } from "@context-providers/error-handler/error-handler-types";
import { IReactChildrenOnly } from "@custom-types/types";
import { ErrorPage } from "@pages/error-page";
import React, { ReactNode } from "react";

/**
 * React error boundary that can catch render errors and show our custom error page:
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
export class ErrorBoundary extends React.Component<IReactChildrenOnly> {
  private error: HandledError | null;

  constructor(props: IReactChildrenOnly) {
    super(props);
    this.error = null;
  }

  componentDidCatch(error: Error): void {
    this.error = {
      id: `componentDidCatch-${Date.now().toString()}`,
      title: "An unexpected error occurred: ",
      error,
      uiType: "page",
    };
  }

  render(): ReactNode {
    return this.error ? <ErrorPage error={this.error} /> : this.props.children;
  }
}
