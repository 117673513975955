import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { colors } from "@styles/common-colors";
import { isValidEmail } from "@utils/email-utils";
import { useEffect, useMemo, useState } from "react";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import {
  createUserAccount,
  isFormValid,
} from "@pages/ulm/signup//create-account-utils";
import { CreateAccountHeader } from "@pages/ulm/signup/create-account-header";
import { isApiError } from "@custom-types/type-guards";
import { AcknowledgementText } from "@components/common/acknowledgement-text";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { UlmPasswordField } from "@components/ulm/ulm-password-field";
import {
  MIN_PASSWORD_LENGTH,
  PasswordValidationChecker,
  useToast,
} from "@faro-lotv/flat-ui";
import { CreateAccountContext } from "@pages/ulm/signup/trial-account/create-trial-accounts-types";
import {
  MainRoutes,
  QueryParams,
  SignUpRoutes,
  SignUpTrialAccountRoutes,
} from "@router/route-params";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { UlmBackButton } from "@components/ulm/ulm-back-button";
import { useAppSelector } from "@store/store-helper";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

export interface CreateAccountFormErrors {
  /** Error message related to first name input field */
  firstName: string | null;

  /** Error message related to first name input field */
  lastName: string | null;

  /** Error message related to password input field */
  password: string | null;

  /** General error message for the create/activate account form */
  message: string | null;

  /** Error message related to email input field */
  email?: string;
}

/** Default error object to set and reset errors state */
export const DEFAULT_ERRORS: CreateAccountFormErrors = {
  firstName: null,
  lastName: null,
  password: null,
  message: null,
};

/** Renders create account page to add user information while sign up */
export function CreateAccount(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { openToast, closeToast } = useToast();
  const { handleError } = useErrorHandlerContext();
  const client = useAppSelector(ulmConfigClientSelector);
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get(QueryParams.email);
  const { navigateToTrialCreateWorkspace } = useAppNavigation();
  const location = useLocation();

  const { accountInfo, updateAccountInfo, isSmallHeightScreen, trialType } =
    useOutletContext<CreateAccountContext>();

  const isInvitedRoute = useMemo(
    () => location.pathname.includes("invited"),
    [location.pathname]
  );

  const hasAssociatedEmail = useMemo(
    () => isValidEmail(emailParam || accountInfo.email),
    [accountInfo.email, emailParam]
  );

  const [errors, setErrors] = useState<CreateAccountFormErrors>(DEFAULT_ERRORS);
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [isPasswordCheckerVisible, setIsPasswordCheckerVisible] =
    useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  useEffect(() => {
    if (emailParam) {
      updateAccountInfo({ email: emailParam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /** Handle email error  */
    if (isValidEmail(accountInfo.email) || !accountInfo.email) {
      setErrors({ ...errors, email: DEFAULT_ERRORS.email });
    } else {
      setErrors({ ...errors, email: "Please provide a valid email!" });
    }

    /** Clear first name error if not empty  */
    if (accountInfo.firstName) {
      setErrors({ ...errors, firstName: DEFAULT_ERRORS.firstName });
    }

    /** Clear last name error if not empty  */
    if (accountInfo.lastName) {
      setErrors({ ...errors, lastName: DEFAULT_ERRORS.lastName });
    }

    /** Clear password error if not empty  */
    if (accountInfo.password) {
      setErrors({ ...errors, password: DEFAULT_ERRORS.password });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore error object as dependency
  }, [accountInfo]);

  /** Start create account process when click on create account button */
  async function handleCreateAccount(): Promise<void> {
    // Early return if the form is not validated
    if (
      !isFormValid({
        email: accountInfo.email,
        firstName: accountInfo.firstName,
        lastName: accountInfo.lastName,
        hasValidPassword: isPasswordValid,
        setErrors,
      })
    ) {
      return;
    }

    /**
     * This function `onContinue` is available when this component is part of creating trial user account
     * Pass user info and early return if onContinue is available
     */
    if (!isInvitedRoute) {
      updateAccountInfo({
        previousLocationRef: `/${MainRoutes.signup}/${SignUpRoutes.trial}/${SignUpTrialAccountRoutes.createAccount}`,
      });
      navigateToTrialCreateWorkspace();
      return;
    }

    setIsCreatingAccount(true);

    // Validate email from backend before create/activate account
    try {
      await coreApiClient.V1.SDB.validateEmail({
        email: accountInfo.email,
        trialType: trialType ?? "",
      });

      await createUserAccount({
        apiClient: coreApiClient,
        email: accountInfo.email,
        firstName: accountInfo.firstName,
        lastName: accountInfo.lastName,
        password: accountInfo.password,
        isSubscribedToNewsLetter: accountInfo.isAlreadySubscribedToNewsLetter,
        client,
      });
    } catch (error) {
      if (isApiError(error)) {
        handleError({
          id: `createAccount-${Date.now().toString()}`,
          title: "Failed to create account",
          error,
          uiType: "none",
        });
        setErrors({ ...errors, message: error.message });
      }
    } finally {
      setIsCreatingAccount(false);
    }
  }

  // Handles the display of errors as toast coming from the backend
  useEffect(() => {
    if (errors.message) {
      openToast({
        title: errors.message,
        variant: "error",
      });
    } else {
      closeToast();
    }
  }, [closeToast, errors.message, openToast]);

  return (
    <UlmContainer>
      <CreateAccountHeader />

      {/* Email input */}
      <FaroInputLabel title="Email" />
      <UlmTextField
        value={accountInfo.email}
        type="email"
        name="email"
        autoComplete="email"
        isDisabled={hasAssociatedEmail}
        isValidValue={isValidEmail(accountInfo.email)}
        isError={!!errors.email}
        errorText={errors.email}
        placeholder="name@company.com"
        onChange={(event) =>
          updateAccountInfo({ email: event.target.value.trim() })
        }
        serverIdentifier={accountInfo.selectedServer}
      />

      {/* First name input */}
      <FaroInputLabel title="First name" />
      <TextField
        fullWidth
        value={accountInfo.firstName}
        type="text"
        name="firstName"
        autoComplete="given-name"
        placeholder="Enter your first name"
        error={!!errors.firstName}
        helperText={errors.firstName}
        onChange={({ target }) =>
          updateAccountInfo({ firstName: target.value.trim() })
        }
      />

      {/* Last name input */}
      <FaroInputLabel title="Last name" />
      <TextField
        fullWidth
        value={accountInfo.lastName}
        type="text"
        name="lastName"
        autoComplete="family-name"
        placeholder="Enter your last name"
        error={!!errors.lastName}
        helperText={errors.lastName}
        onChange={({ target }) =>
          updateAccountInfo({ lastName: target.value.trim() })
        }
      />

      {/* Password name input */}
      <UlmPasswordField
        value={accountInfo.password}
        placeholder={`Enter at least ${MIN_PASSWORD_LENGTH} characters`}
        onChange={({ target }) => updateAccountInfo({ password: target.value })}
        hasError={
          !!errors.password ||
          (!isPasswordValid && accountInfo.password.length > 0)
        }
        helperText={errors.password}
        onFocus={() => setIsPasswordCheckerVisible(true)}
        onBlur={() => isPasswordValid && setIsPasswordCheckerVisible(false)}
      />

      {isPasswordCheckerVisible && (
        <Box width={"100%"}>
          <PasswordValidationChecker
            password={accountInfo.password}
            onValidationChange={setIsPasswordValid}
          />
        </Box>
      )}

      {/* Checkbox to subscribe newsletter email */}
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            checked={accountInfo.isAlreadySubscribedToNewsLetter}
            onChange={(event) =>
              updateAccountInfo({
                isAlreadySubscribedToNewsLetter: event.target.checked,
              })
            }
            data-testid="newsletter-checkbox"
            size="small"
          />
        }
        label="I want to subscribe to the newsletter for updates on new features and more"
        sx={{
          color: colors.gray700,
          alignItems: "center",
          display: "flex",
          mb: "4px",
          "& .MuiFormControlLabel-label": {
            fontSize: "10px",
          },
        }}
      />

      {/* Create account button */}
      <UlmButton
        isLoading={isCreatingAccount}
        isLoadingText={"Get started"}
        buttonText={isInvitedRoute ? "Get started" : "Continue"}
        onClick={handleCreateAccount}
        sx={{
          marginTop: isSmallHeightScreen ? "10px" : "20px",
        }}
      />

      <UlmBackButton route={accountInfo.previousLocationRef} />

      {/* Terms of use and privacy policy text */}
      <AcknowledgementText />
    </UlmContainer>
  );
}
