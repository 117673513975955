import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { colors } from "@styles/common-colors";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { useState } from "react";
import VisibilityOnIcon from "@assets/icons/visibility-on_24px.svg?react";
import VisibilityOffIcon from "@assets/icons/visibility-off_24px.svg?react";

export interface Props extends Omit<TextFieldProps, "variant"> {
  /** Input value */
  value: string;

  /** Whether to show/hide the label */
  shouldShowLabel?: boolean;

  /** True if the field has error to be displayed */
  hasError?: boolean;

  /** Optional component to show on the right side of the label */
  helpComponent?: React.ReactNode;

  /** True if the text field should be disabled */
  isDisabled?: boolean;

  /** Set the input name label */
  label?: string;
}

/** Renders the password field */
export function UlmPasswordField({
  value,
  hasError,
  shouldShowLabel = true,
  helpComponent,
  placeholder = "Enter your password",
  isDisabled = false,
  label = "Password",
  ...rest
}: Props): JSX.Element {
  const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);

  return (
    <>
      {shouldShowLabel && (
        <FaroInputLabel title={label} helpComponent={helpComponent} />
      )}

      <TextField
        fullWidth
        value={value}
        type={shouldShowPassword ? "text" : "password"}
        name="password"
        placeholder={placeholder}
        autoComplete="password"
        error={hasError}
        disabled={isDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShouldShowPassword(!shouldShowPassword)}
                disableRipple
                sx={{ color: colors.gray500 }}
              >
                {shouldShowPassword ? (
                  <VisibilityOnIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </>
  );
}
