import {
  EServerDisplayText,
  ServerOption,
  UlmClientFlagType,
  UlmClientType,
  UlmTrialType,
} from "@custom-types/ulm-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** List of all available ULM clients */
export const UlmClients: { [key in UlmClientType]: key } = {
  /** Default client */
  default: "default",

  /** FARO Sphere Dashboard */
  sphereDashboard: "sphereDashboard",

  /** FARO Connect (GeoSlam) */
  connect: "connect",

  /** Orbis customers using Stream */
  streamOrbis: "streamOrbis",

  /** Focus customers using Stream */
  streamFocus: "streamFocus",

  /** Sphere Viewer */
  sphereViewer: "sphereViewer",

  /** FARO Scene */
  scene: "scene",
};

/** List of all available ULM client flags */
export const UlmClientFlags: { [key in UlmClientFlagType]: key } = {
  /** The signup workflow will go directly through the ULM */
  directSignup: "directSignup",

  /** The signup workflow will go through the SalesForce HB Trial page */
  sfSignup: "sfSignup",

  /** Sphere 1 login option available */
  sphere1Login: "sphere1Login",

  /** Sphere 1 login option not available */
  noSphere1Login: "noSphere1Login",

  /** Sphere 2 login option available */
  sphere2Login: "sphere2Login",

  /** Sphere 2 login option not available */
  noSphere2Login: "noSphere2Login",
};

/* eslint-disable @typescript-eslint/naming-convention -- names defined by backend */
/** List of all supported ULM trial types */
export const UlmTrialTypes: { [key in UlmTrialType]: key } = {
  /** Faro Connect trial */
  "cloud-processing-faro-connect": "cloud-processing-faro-connect",

  /** Cloud processing trial */
  "cloud-processing-gated-trial": "cloud-processing-gated-trial",
};
/* eslint-enable */

/** List of servers available for signing-up/login in SphereXG */
export const serverOptions: ServerOption[] = [
  {
    identifier: SphereDashboardAPITypes.EServerIdentifier.sphere2Us,
    text: EServerDisplayText.sphere2Us,
  },
  {
    identifier: SphereDashboardAPITypes.EServerIdentifier.sphere2Eu,
    text: EServerDisplayText.sphere2Eu,
  },
];
