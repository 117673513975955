import { configureStore } from "@reduxjs/toolkit";
import { AppDispatch } from "@store/store-helper";
import {
  resetUlmConfigState,
  ulmConfigReducer,
} from "@store/ulm-config/ulm-config-slice";
import { loginReducer, resetLoginState } from "@store/login/login-slice";
import { errorsReducer, resetErrorsState } from "@store/errors/errors-slice";

export const rootReducer = {
  // When adding a new reducer here also add the corresponding reset action to the "resetStore" function.
  ulmConfig: ulmConfigReducer,
  login: loginReducer,
  errors: errorsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

/**
 * Resets the store. Dispatches all reducers reset actions. It can be called from any component.
 * For every new store slice added it's corresponding reset action must be added here.
 *
 * @param dispatch AppDispatch
 */
export function resetStore(dispatch: AppDispatch): void {
  dispatch(resetUlmConfigState());
  dispatch(resetLoginState());
  dispatch(resetErrorsState());
}
