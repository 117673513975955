import { useAppDispatch } from "@store/store-helper";
import { setUlmConfigState } from "@store/ulm-config/ulm-config-slice";
import { getUlmConfig } from "@utils/ulm-utils";
import { setLoginState } from "@store/login/login-slice";
import { getLoginState } from "@utils/login-config-utils";
import { useEffect } from "react";

/**
 * Sets the ULM and login config state based on the configuration query params:
 * https://faro01.atlassian.net/wiki/spaces/HBWEBDEV/pages/3640983567/ULM+Query+parameters
 */
export function useUlmConfig(): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const ulmConfigState = getUlmConfig();
    const loginState = getLoginState(ulmConfigState);

    dispatch(setUlmConfigState(ulmConfigState));
    dispatch(setLoginState(loginState));
  }, [dispatch]);
}
