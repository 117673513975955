import {
  ApiClient,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  CreateAccountFormErrors,
  DEFAULT_ERRORS,
} from "@pages/ulm/signup/create-account";
import { isValidEmail } from "@utils/email-utils";
import { ReactSetStateFunction } from "@custom-types/types";
import { runtimeConfig } from "@src/runtime-config";
import { BrowserUtils } from "@stellar/web-core";
import qs from "qs";
import { getApiClientBasedOnServer } from "@utils/ulm-utils";
import { UlmClientType } from "@custom-types/ulm-types";
import { UlmClients } from "@constants/ulm-constants";
import { requestParentToRedirect } from "@utils/browser-utils";

interface CreateUserProps {
  /** The core-api client */
  apiClient: ApiClient;

  /** Email address from the create account form */
  email: string;

  /** First name from the create account form */
  firstName: string;

  /** Last name from the create account form */
  lastName: string;

  /** Password from the create account form */
  password: string;

  /** True if user subscribed the newsletter email */
  isSubscribedToNewsLetter: boolean;

  /** ULM client */
  client: UlmClientType | null;
}

/** Create or activate user account */
export async function createUserAccount({
  apiClient,
  email,
  firstName,
  lastName,
  password,
  isSubscribedToNewsLetter,
  client,
}: CreateUserProps): Promise<void> {
  const userData = {
    /* eslint-disable @typescript-eslint/naming-convention -- defined on the backend */
    rgst_email: email,
    rgst_first_name: firstName.trim(),
    rgst_last_name: lastName.trim(),
    rgst_password: password,
    /* eslint-enable @typescript-eslint/naming-convention -- defined on the backend */

    newsletter: isSubscribedToNewsLetter ? "on" : "off",
  };

  const payload = qs.stringify(userData, { arrayFormat: "brackets" });

  // eslint-disable-next-line no-useless-catch -- catch error in previous block
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Defined by backend
    const registeredUserData: any = await apiClient.V1.extendedRegister(
      payload
    );

    // Set user mail consent
    let mailConsents: CoreAPITypes.EMailConsent[] = [];
    if (userData.newsletter === "on") {
      mailConsents = [
        CoreAPITypes.EMailConsent.newsletter,
        CoreAPITypes.EMailConsent.promotional,
      ];
    }

    await apiClient.V3.setUserMailConsents(
      registeredUserData.userId
        ? registeredUserData.userId
        : registeredUserData.data.userId,
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Defined by backend
        mail_consent: mailConsents,
      }
    );

    const url = new URL(runtimeConfig.urls.sphereDashboardUrl);

    // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
    // Instead of a simple redirect we need to send a window message so they redirect instead
    if (
      client === UlmClients.sphereDashboard ||
      client === UlmClients.sphereViewer
    ) {
      requestParentToRedirect(url);
    } else {
      BrowserUtils.redirectToUrl(url.href);
    }
  } catch (error) {
    throw error;
  }
}

interface CreateTrialUserAccount {
  /** Email input value entered by user */
  payload:
    | CoreAPITypes.IRegisterTrialEmailPayload
    | CoreAPITypes.IRegisterTrialOAuthPayload;

  /** Selected server selected by user */
  selectedServer: SphereDashboardAPITypes.EServerIdentifier;

  /** ULM client */
  client: UlmClientType | null;
}

/** Create trial user account */
export async function createTrialUserAccount({
  payload,
  selectedServer,
  client,
}: CreateTrialUserAccount): Promise<void> {
  // eslint-disable-next-line no-useless-catch -- catch error in previous block
  try {
    // Api client based on the selected server
    const coreApiClient = getApiClientBasedOnServer(selectedServer);

    const redirectUrl =
      selectedServer === SphereDashboardAPITypes.EServerIdentifier.sphere2Us
        ? runtimeConfig.urls.dashboard20ComUrl
        : runtimeConfig.urls.dashboard20EuUrl;

    const { redirect: redirectToDashboard } =
      await coreApiClient.V3.registerTrial(payload, redirectUrl);

    // Redirect the page manually to the target that backend provides.
    const url = new URL(redirectToDashboard);

    // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
    // Instead of a simple redirect we need to send a window message so they redirect instead
    if (
      client === UlmClients.sphereDashboard ||
      client === UlmClients.sphereViewer
    ) {
      requestParentToRedirect(url);
    } else {
      BrowserUtils.redirectToUrl(url.href);
    }
  } catch (error) {
    throw error;
  }
}

interface FormValidationProps {
  /** Email address from the create account form */
  email: string;

  /** First name from the create account form */
  firstName: string;

  /** Last name from the create account form */
  lastName: string;

  /** Password from the create account form */
  hasValidPassword: boolean;

  /** Callback function to update the errors state */
  setErrors: ReactSetStateFunction<CreateAccountFormErrors>;
}

/**
 * Validates the create/activate
 * Returns true if form is valid
 */
export function isFormValid({
  email,
  firstName,
  lastName,
  hasValidPassword,
  setErrors,
}: FormValidationProps): boolean {
  let isValid = true;
  const inputErrors: CreateAccountFormErrors = { ...DEFAULT_ERRORS };

  if (!isValidEmail(email)) {
    inputErrors.email = "Please provide a valid email!";
    isValid = false;
  }

  if (firstName.trim() === "") {
    inputErrors.firstName = "Please provide your first name";
    isValid = false;
  }

  if (lastName.trim() === "") {
    inputErrors.lastName = "Please provide your last name";
    isValid = false;
  }

  if (!hasValidPassword) {
    inputErrors.password = "Please provide a strong password";
    isValid = false;
  }

  setErrors({ ...inputErrors });
  return isValid;
}

/**
 * Checks if the given email address is a valid Faro email.
 * A valid Faro email address:
 * - Must end with "@faro.com"
 * - Must not contain a '+' character in the local part (the part before '@')
 *
 * @param {string} email - The email address to check.
 */
export function isFaroEmail(email: string): boolean {
  const localPart = email.split("@")[0];
  return email.endsWith("@faro.com") && !localPart.includes("+");
}
