import { runtimeConfigObject } from "@src/app-config";

declare global {
  interface Window {
    /** Our build/server will make sure to inject this runtimeConfig */
    appConfig?: RuntimeConfig;
  }
}

export type RuntimeConfig = {
  readonly urls: {
    readonly apiBaseUrl: string;
    readonly apiBaseComUrl: string;
    readonly apiBaseEuUrl: string;
    readonly sphereDashboardUrl: string;
    readonly dashboard20ComUrl: string;
    readonly dashboard20EuUrl: string;
    readonly sphereEntryPageComUrl: string;
    readonly sphereEntryPageEuUrl: string;
    readonly sphereEntryPageApi: string;
    readonly sphere10Url: string;
  };
  readonly features: {
    readonly localizeKey: string;
    readonly sphere10Auth0Domain: string;
    readonly sphere10Auth0ClientId: string;
    readonly streamAppAuth0ClientId: string;
    readonly ulmClientsFlags: {
      readonly ulmClientDefaultFlags: string;
      readonly ulmClientSphereDashboardFlags: string;
      readonly ulmClientConnectFlags: string;
      readonly ulmClientSceneFlags: string;
      readonly ulmClientStreamOrbisFlags: string;
      readonly ulmClientStreamFocusFlags: string;
      readonly ulmClientSphereViewerFlags: string;
    };
    readonly hasCompanySSO: boolean;
    readonly sentryDSNKey?: string;
  };
  readonly oauth: {
    readonly providers: string[];
  };
  readonly appEnv: string;
  readonly appVersion: string;
  readonly appTimestamp: string;
  readonly mailSupportAddress: string;
  readonly commitId: string;
  readonly commitUrl: string;
  readonly pullRequestId: string;
  readonly pullRequestUrl: string;
  readonly jiraTicketId: string;
  readonly jiraTicketUrl: string;
  /** Flag whether the current domain is running on a numbered environment */
  readonly isNumberedEnv: boolean;
  /** The numbered environment if the current domain is running on a numbered environment */
  readonly numberedEnv: string | null;
};

class RuntimeConfigReader {
  static getRuntimeEnv(hostUrl: string): string {
    try {
      const matches = hostUrl.match(
        /.*dev\d\.entry\.(staging|dev)\.holobuilder.(eu|com)/
      );
      if (!matches) {
        if (hostUrl === "localhost") {
          for (const key in runtimeConfigObject) {
            if (
              runtimeConfigObject[key].appEnv === import.meta.env.VITE_APP_ENV
            ) {
              return `${key}Local`;
            }
          }
        }
        if (hostUrl === "entry.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "entry.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl === "test.entry.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "test.entry.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl.endsWith("entry.dev.holobuilder.com")) {
          return "developmentCom";
        }
        if (hostUrl.endsWith("entry.staging.holobuilder.com")) {
          return "stagingCom";
        }
        if (hostUrl.endsWith("entry.dev.holobuilder.eu")) {
          return "developmentEu";
        }
        if (hostUrl.endsWith("entry.staging.holobuilder.eu")) {
          return "stagingEu";
        }
        // Default case if no specific conditions are met
        return "developmentCom";
      }

      const env =
        matches[1] && matches[1] === "dev" ? "development" : "staging";
      const region = matches[2] === "com" ? "Com" : "Eu";
      return `${env}${region}`;
    } catch (err) {
      throw new Error(`Failed to open env file for dev environment ${hostUrl}`);
    }
  }
  static getRuntimeConfig(): RuntimeConfig {
    /* eslint-disable no-restricted-globals */
    const hostUrl = location.hostname;
    /* eslint-enable no-restricted-globals */
    const runtimeEnv = this.getRuntimeEnv(hostUrl);
    return runtimeConfigObject[runtimeEnv];
  }
}

window.appConfig = RuntimeConfigReader.getRuntimeConfig();

// If the runtimeConfig is missing something is broken in the dev server or prod server
if (!window.appConfig) {
  throw Error("Runtime config missing");
}

const runtimeConfig = window.appConfig;
// make it immutable
Object.freeze(runtimeConfig);

export { runtimeConfig };
