import { QueryParams } from "@router/route-params";
import { useAppSelector } from "@store/store-helper";
import { ulmConfigFlagsSelector } from "@store/ulm-config/ulm-config-selector";
import { useSearchParams } from "react-router-dom";

interface UseGetTrialType {
  /** Registration trial type value */
  trialType: string | null;
}

/** Returns trial type as it is if available in the query params, Otherwise based on the client */
export function useGetTrialType(): UseGetTrialType {
  const { trialType } = useAppSelector(ulmConfigFlagsSelector);
  const [searchParams] = useSearchParams();

  const trialTypeParam = searchParams.get(QueryParams.trialType);

  // Returns as it is if available in query params
  if (trialTypeParam && trialTypeParam !== "") {
    return { trialType: trialTypeParam };
  }

  // If no trial type available in the query params then return the client default trial type
  return { trialType };
}
