import { UlmClientType } from "@custom-types/ulm-types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { UlmConfigState } from "@store/ulm-config/ulm-config-slice";

/**
 * Returns the ULM config client ID
 */
export const ulmConfigClientSelector: (
  state: RootState
) => UlmClientType | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.ulmConfig.client;
  }
);

/**
 * Returns the ULM config client flags
 */
export const ulmConfigFlagsSelector: (
  state: RootState
) => UlmConfigState["configFlags"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.ulmConfig.configFlags;
  }
);
