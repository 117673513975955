import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { DEFAULT_FONT_FAMILY } from "@styles/common-styles";

interface Props {
  /**
   * The title that will be shown on the left side of the component.
   */
  title: string;

  /**
   * Whether the field is disabled.
   * If true, it shows the text as opaque.
   */
  isDisabled?: boolean;

  /** Optional component to show on the right side of the label */
  helpComponent?: React.ReactNode;
}

/**
 * Opacity float value in a 0-1 range to show when the field is disabled.
 */
const DISABLED_OPACITY = 0.5;

/**
 * Renders a label that can be used in forms.
 * The component takes the full width of the parent and on the left side places a label.
 */
export function FaroInputLabel({
  title,
  isDisabled = false,
  helpComponent,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        fontFamily: DEFAULT_FONT_FAMILY,
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "2px",
        width: "100%",
        opacity: isDisabled ? DISABLED_OPACITY : 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* Left side of the label */}
      <Box
        component="div"
        sx={{
          opacity: isDisabled ? DISABLED_OPACITY : 1,
          mr: "10px",
        }}
      >
        <Box component="span">{title}</Box>
        {/* Optional blue asterisk */}
      </Box>

      {/* Right side component like links, help icon */}
      {helpComponent}
    </Box>
  );
}
