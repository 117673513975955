import { ActiveServer } from "@custom-types/ulm-types";
import { createSelector } from "@reduxjs/toolkit";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import { ulmConfigFlagsSelector } from "@store/ulm-config/ulm-config-selector";
import {
  getActiveServer,
  haveActiveServersBothSphereVersions,
  isServerActive,
  sortServers,
} from "@utils/servers-utils";
import { filterActiveServers } from "@utils/ulm-utils";

/**
 * Returns the prefill email
 */
export const prefillEmailSelector: (state: RootState) => string | undefined =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.login.prefillEmail;
    }
  );

/**
 * Returns true iff the prefilled email has been checked for active servers in the backend
 */
export const hasCheckPrefillEmailSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.login.hasCheckPrefillEmail ?? false;
    }
  );

/**
 * Returns the server option
 */
export const serverOptionSelector: (
  state: RootState
) => SphereDashboardAPITypes.EServerIdentifier | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.login.serverOption;
  }
);

/**
 * Returns the preselected server
 */
export const preselectedServerSelector: (
  state: RootState
) => SphereDashboardAPITypes.EServerIdentifier | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.login.preselectedServer;
  }
);

/**
 * Returns true if the preselected server has been selected
 */
export const hasSelectedPreselectedServerSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.login.hasSelectedPreselectedServer ?? false;
  }
);

/**
 * Returns whether the "Start free trial" link should be hidden
 */
export const shouldHideRegistrationSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.login.shouldHideRegistration ?? false;
    }
  );

/**
 * Returns the fetched servers
 */
export const fetchedServersSelector: (
  state: RootState
) => SphereDashboardAPITypes.Server[] | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.login.fetchedServers;
  }
);

/**
 * Returns true iff servers are being fetched from the backend
 */
export const isFetchingServersSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.login.isFetchingServers ?? false;
    }
  );

/**
 * Returns the active servers
 */
export const activeServersSelector: (
  state: RootState
) => ActiveServer[] | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const servers = fetchedServersSelector(state);

    if (!servers) {
      return;
    }

    const activeServers = servers.filter(isServerActive);

    // Filter servers with active status by the ULM client login options
    // and by the "serverOption" flag
    const configFlags = ulmConfigFlagsSelector(state);
    const serverOption = serverOptionSelector(state);
    const filteredActiveServers = filterActiveServers({
      activeServers,
      configFlags,
      serverOption,
    });

    const filteredActiveServersIdentifier = filteredActiveServers.map(
      (server) => server.identifier
    );

    const shouldUseDisplayTextVariant = haveActiveServersBothSphereVersions(
      filteredActiveServersIdentifier
    );

    // Get the active server entities with the display text included
    const activeServerEntities = filteredActiveServers.map((server) =>
      getActiveServer(server, shouldUseDisplayTextVariant)
    );

    // Finally sort the active server entities according to the default order
    const sortedFilteredActiveServers = sortServers(activeServerEntities);

    return sortedFilteredActiveServers;
  }
);
