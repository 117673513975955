import { REDIRECT_WHITELIST_REGEX } from "@constants/redirect-whitelist";
import { UlmRedirectMessage } from "@faro-lotv/gate-keepers";

/**
 * Sends a message to the parent window with the redirect URL
 *
 * @param redirectUrl Redirect URL
 */
export function requestParentToRedirect(redirectUrl: URL): void {
  const message: UlmRedirectMessage = {
    identifier: "UlmRedirectMessage",
    redirectUrl: redirectUrl.href,
  };

  window.parent.postMessage(message, "*");
}

/**
 * @returns whether the origin of the passed URL is valid according to the redirect whitelist.
 *
 * @param url URL string.
 */
export function isValidRedirectUrl(url: string): boolean {
  try {
    const urlOrigin = new URL(url).origin;
    return REDIRECT_WHITELIST_REGEX.some((regex) => regex.test(urlOrigin));
  } catch (error) {
    return false;
  }
}
