import { useMemo } from "react";
import { ApiClient } from "@stellar/api-logic";
import { runtimeConfig } from "@src/runtime-config";
import { getApiClient } from "@api/core-api/core-api-utls";

/**
 * Initializes client to Core.API if not already initialized and returns it
 */
export function useCoreApiClient(): ApiClient {
  // Make sure the client is only created once
  const memoizedClient = useMemo(
    () => getApiClient(runtimeConfig.urls.apiBaseUrl),
    []
  );

  return memoizedClient;
}
