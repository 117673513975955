// eslint-disable-next-line no-restricted-imports -- error handler is not available at this level
import { Logger } from "@stellar/web-core";
import { runtimeConfig } from "@src/runtime-config";
import { LocalStorageUtils } from "@utils/local-storage-utils";
import { getPathnameFromUrl, getQueryParamsFromSearch } from "@utils/url-utils";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Localization } from "@utils/localization";
import { useSphereXGApi } from "@hooks/use-sphere-xg-api";
import { QueryParams } from "@router/route-params";

/**
 * No matter the dependencies it seems that the useEffect to call the init route
 * is called twice, so it stores similar to the isApiInitialized state, whether
 * initialized was already called.
 */
let isApiInitializedLocally = false;

/**
 * Hook to initialize the user in the dashboard 2.0 API
 */
export function useTrackPageLoad(): void {
  const [isApiInitialized, setIsApiInitialized] = useState<boolean>(false);
  const routeLocation = useLocation();
  const appParams = useParams();
  const { postInit } = useSphereXGApi();

  // Call the routes to initialize the user in the dashboard 2.0 API
  // This route will take care of tracking the page load in Amplitude for all users.
  useEffect(() => {
    async function trackPageLoaded(): Promise<void> {
      const language = Localization.selectedLanguage();
      const urlPathname = getPathnameFromUrl(routeLocation.pathname, appParams);
      const urlQuery = getQueryParamsFromSearch(routeLocation.search);

      const deviceSizes = {
        screenWidth: window.screen.width,
        windowWidth: window.innerWidth,
        screenHeight: window.screen.height,
        windowHeight: window.innerHeight,
        windowOrientation: window.screen.orientation?.type,
      };

      try {
        const appInitTime =
          LocalStorageUtils.getStringItem("app-init-time") ?? undefined;
        const search = new URLSearchParams(routeLocation.search);

        const response = await postInit({
          version: runtimeConfig.appVersion,
          application: "Sphere XG EntryPage",
          userId: "SPHERE_SXG_ENTRY_PAGE_USER",
          appInitTime,
          language,
          urlPathname,
          urlQuery,
          ulmClient: search.get(QueryParams.client) ?? undefined,
          ulmClientVersion: search.get(QueryParams.version) ?? undefined,
          ...deviceSizes,
        });
        if (response.success) {
          LocalStorageUtils.setStringItem(
            "app-init-time",
            Date.now().toString()
          );
        }
      } catch (error) {
        // Failing to call the init route should not block the user from using the app
        // Use Sentry to log the error
        Logger.logError("Error calling init route", { error: error as Error });
      }
    }

    if (
      !isApiInitialized &&
      !isApiInitializedLocally &&
      routeLocation?.pathname &&
      appParams
    ) {
      isApiInitializedLocally = true;
      // Call this upfront to avoid multiple calls while the promise is being resolved
      setIsApiInitialized(true);
      trackPageLoaded();
    }
  }, [appParams, isApiInitialized, postInit, routeLocation]);
}
