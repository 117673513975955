/**
 * List of allowed redirect URLs in the form of regular expressions.
 * More whitelisted URLs regex can be added in the future.
 */
export const REDIRECT_WHITELIST_REGEX: RegExp[] = [
  /**
   * This regex matches these URL wildcards:
   * - https://*.holobuilder.com
   * - https://*.holobuilder.eu
   * - https://*.faro.com
   * - https://*.faro.eu
   * - https://*.spherexg.com
   * - https://*.spherexg.eu
   */
  /^https:\/\/(.*\.)?(holobuilder|faro|spherexg)\.(com|eu)$/,

  /**
   * Known localhost ports for development in local:
   * - http://localhost:8080
   * - http://localhost:8888
   * - http://localhost:3000
   */
  /^http:\/\/localhost:(8080|8888|3000)$/,
];
