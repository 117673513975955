import { UlmClients } from "@constants/ulm-constants";
import { QueryParams } from "@router/route-params";
import { LoginState, initialState } from "@store/login/login-slice";
import { UlmConfigState } from "@store/ulm-config/ulm-config-slice";
import {
  getVisitedServerIdentifier,
  isValidServerIdentifier,
} from "@utils/ulm-utils";

/**
 * Returns the login state based on the ULM client and the configuration query parameters:
 * https://faro01.atlassian.net/wiki/spaces/HBWEBDEV/pages/3640983567/ULM+Query+parameters
 *
 * - Gets the prefillEmail value from the "email" query param
 * - Gets the serverOption value from the client or the "server" query param
 * - Gets the "preselectedServer" value from the "preselectedServer" query param
 * - Gets the "shouldHideRegistration" value from the "hideRegistration" query param
 */
export function getLoginState(ulmConfigState: UlmConfigState): LoginState {
  const searchParams = new URLSearchParams(window.location.search);
  const loginState = { ...initialState };

  // prefillEmail
  const emailParam = searchParams.get(QueryParams.email);
  if (emailParam && emailParam.length > 0) {
    loginState.prefillEmail = emailParam;
  }

  // serverOption
  /**
   * Special case for the "sphereViewer" client where the only server option
   * should be the the same as the current ULM instance.
   */
  if (ulmConfigState.client === UlmClients.sphereViewer) {
    const visitedServerIdentifier = getVisitedServerIdentifier();
    if (visitedServerIdentifier) {
      loginState.serverOption = visitedServerIdentifier;
    }
  }
  const serverParam = searchParams.get(QueryParams.server);
  if (serverParam && isValidServerIdentifier(serverParam)) {
    loginState.serverOption = serverParam;
  }

  // preselectedServer
  const preselectedServerParam = searchParams.get(
    QueryParams.preselectedServer
  );
  if (
    preselectedServerParam &&
    isValidServerIdentifier(preselectedServerParam)
  ) {
    loginState.preselectedServer = preselectedServerParam;
  }

  // shouldHideRegistration
  const hideRegistrationParam = searchParams.get(QueryParams.hideRegistration);
  if (hideRegistrationParam && hideRegistrationParam === "true") {
    loginState.shouldHideRegistration = true;
  }

  return loginState;
}
