import { BaseError } from "@context-providers/error-handler/error-handler-types";
import { StatusCodes } from "http-status-codes";
import { CoreAPIUtils } from "@stellar/api-logic";

/**
 * Converts errors of unknown shape to a readable markup.
 */
export function getErrorDisplayMarkup(error: BaseError): string {
  // errorMarkup might be in the form of html tag
  let errorMarkup: string = "";

  if (error) {
    if (typeof error === "string") {
      errorMarkup = error;
    } else if ((error as Error).message) {
      // Try to find a message in the error-like object
      if (typeof (error as Error).message === "string") {
        errorMarkup = (error as Error).message;
      } else {
        // Try to display the message stringified with JSON if it is an object.
        try {
          errorMarkup = JSON.stringify((error as Error).message, null, 2);
        } catch (ignoreJsonError) {
          errorMarkup = `${(error as Error).message}`;
        }
      }
    } else if (
      CoreAPIUtils.isResponseError(error) &&
      error.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      errorMarkup = "Something went wrong";
    } else if (
      CoreAPIUtils.isResponseError(error) &&
      error.status === StatusCodes.UNAUTHORIZED
    ) {
      errorMarkup = error.error_v2;
    } else if (CoreAPIUtils.isBaseResponse(error)) {
      errorMarkup = `${errorMarkup} (error status: ${error.status})`;
    }
  }

  if (!errorMarkup) {
    errorMarkup = "No error message";
  }

  return errorMarkup;
}
