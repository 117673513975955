import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { UlmPasswordField } from "@components/ulm/ulm-password-field";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "@styles/common-colors";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { PasswordValidationChecker, useToast } from "@faro-lotv/flat-ui";
import { validateConfirmPasswordError } from "@pages/ulm/password/password-utils";
import { PasswordChanged } from "@pages/ulm/info-messages/password-changed";
import { useSearchParams } from "react-router-dom";
import { QueryParams } from "@router/route-params";
import { isApiError } from "@custom-types/type-guards";
import { StatusCodes } from "http-status-codes";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

/**
 * `ResetPassword` component is responsible for allowing users to reset their password.
 */
export function ResetPassword(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const [searchParams] = useSearchParams();
  const keyValue = searchParams.get(QueryParams.key) || undefined;
  const { openToast } = useToast();
  const { handleError } = useErrorHandlerContext();

  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
  const [isPasswordResetting, setIsPasswordResetting] =
    useState<boolean>(false);
  const [isResetPasswordWithSuccess, setIsResetPasswordWithSuccess] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const isButtonDisabled = useMemo(() => {
    return password !== confirmPassword || !isPasswordValid;
  }, [password, confirmPassword, isPasswordValid]);

  const resetPassword = useCallback(async () => {
    const errorTitle = "Failed to reset the password.";

    try {
      setIsPasswordResetting(true);

      await coreApiClient.V1.SDB.setNewPassword({
        /* eslint-disable @typescript-eslint/naming-convention -- naming defined by backend */
        setnewpw_key: keyValue,
        setnewpw_pw: password,
        setnewpw_pw_2: confirmPassword,
        /* eslint-enable @typescript-eslint/naming-convention */
      });

      setIsResetPasswordWithSuccess(true);
    } catch (error) {
      // The backend request fails with 400 if the key is missing or with 404 if the key is invalid
      // or if the new password is the same as the old password. For those cases we should not log
      // the error to Sentry.
      if (
        isApiError(error) &&
        (error.status === StatusCodes.NOT_FOUND ||
          error.status === StatusCodes.BAD_REQUEST)
      ) {
        openToast({
          title: errorTitle,
          message: error.message,
          variant: "error",
        });
      } else {
        handleError({
          id: `setNewPassword-${Date.now().toString()}`,
          title: errorTitle,
          error,
          uiType: "toast",
        });
      }
    } finally {
      setIsPasswordResetting(false);
    }
  }, [
    confirmPassword,
    coreApiClient.V1.SDB,
    handleError,
    keyValue,
    openToast,
    password,
  ]);

  const onPasswordKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (!isButtonDisabled && event.key === "Enter") {
        resetPassword();
      }
    },
    [isButtonDisabled, resetPassword]
  );

  function updateConfirmPassword(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length >= password.length) {
      const confirmValidationError = validateConfirmPasswordError(
        password,
        event.target.value
      );
      setConfirmPasswordError(confirmValidationError);
    }

    setConfirmPassword(event.target.value);
  }

  if (isResetPasswordWithSuccess) {
    return <PasswordChanged />;
  }

  return (
    <UlmContainer>
      <Typography
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Reset your password?
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          color: colors.gray700,
        }}
      >
        You want something secure and no shorter than 14 characters.
      </Typography>

      <Stack
        sx={{
          marginTop: "100px",
          width: "100%",
        }}
      >
        <UlmPasswordField
          value={password}
          placeholder={"Enter your new password"}
          hasError={!isPasswordValid && !!password.length}
          onKeyUp={onPasswordKeyUp}
          onChange={(event) => setPassword(event.target.value)}
        />

        <Box marginBottom="12px">
          <PasswordValidationChecker
            password={password}
            onValidationChange={setIsPasswordValid}
          />
        </Box>

        <UlmPasswordField
          value={confirmPassword}
          placeholder={"Confirm your new password"}
          label={"Confirm password"}
          helperText={confirmPasswordError}
          hasError={!!confirmPasswordError}
          onKeyUp={onPasswordKeyUp}
          onChange={updateConfirmPassword}
        />
      </Stack>

      <UlmButton
        buttonText="Set password"
        isDisabled={isButtonDisabled}
        onClick={resetPassword}
        isLoading={isPasswordResetting}
        isLoadingText="Updating..."
        sx={{
          marginTop: "8px",
        }}
      />
    </UlmContainer>
  );
}
