import { HandledError } from "@context-providers/error-handler/error-handler-types";
import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  isRejected,
} from "@reduxjs/toolkit";
import { fetchEmailServers } from "@store/login/login-slice";
import { BaseEntityState } from "@store/store-types";
import { createHandledError } from "@store/errors/errors-slice-utils";

export const errorsAdapter: EntityAdapter<HandledError> = createEntityAdapter();

export const initialState: BaseEntityState<HandledError> = {
  ...errorsAdapter.getInitialState(),
};

/**
 * Slice to access state of errors
 */
const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setOne: errorsAdapter.setOne,
    removeOne: errorsAdapter.removeOne,
    resetErrorsState: () => initialState,
  },

  extraReducers(builder) {
    builder.addMatcher(isRejected(fetchEmailServers), (state, action) => {
      errorsAdapter.setOne(state, createHandledError(action));
    });
  },
});

export const { setOne, removeOne, resetErrorsState } = errorsSlice.actions;

export const errorsReducer = errorsSlice.reducer;
