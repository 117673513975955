import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { TrialAccountInfo } from "@pages/ulm/signup/trial-account/create-trial-accounts-types";
import { useMediaQueries } from "@hooks/use-media-queries";
import { useGetTrialType } from "@hooks/ulm/use-get-trial-type";
import { getVisitedServerIdentifier } from "@utils/ulm-utils";

/** Default information for trial user account */
const DEFAULT_TRIAL_ACCOUNT_INFO: TrialAccountInfo = {
  email: "",
  selectedServer:
    getVisitedServerIdentifier() ||
    SphereDashboardAPITypes.EServerIdentifier.sphere2Us,
  firstName: "",
  lastName: "",
  password: "",
  workspaceName: "",
  isAlreadySubscribedToNewsLetter: false,
  previousLocationRef: "",
};

/**
 * Provides a wrapper component for managing the state and context related to account creation.
 */
export function CreateAccountWrapper(): JSX.Element {
  const location = useLocation();

  const { isSmallHeightScreen } = useMediaQueries();
  const { trialType } = useGetTrialType();

  const [accountInfo, setAccountInfo] = useState<TrialAccountInfo>(() => {
    const savedAccountInfo = localStorage.getItem("accountInfo");

    return savedAccountInfo
      ? JSON.parse(savedAccountInfo)
      : DEFAULT_TRIAL_ACCOUNT_INFO;
  });

  useEffect(() => {
    function handleBeforeUnload(): void {
      const accountInfoToSave = {
        ...accountInfo,
        password: "",
      };

      localStorage.setItem("accountInfo", JSON.stringify(accountInfoToSave));
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [accountInfo]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("accountInfo");
    };
  }, [location]);

  /** Update account info */
  function updateAccountInfo(updates: Partial<TrialAccountInfo>): void {
    setAccountInfo((prevInfo) => ({
      ...prevInfo,
      ...updates,
    }));
  }

  return (
    <Outlet
      context={{
        updateAccountInfo,
        accountInfo,
        isSmallHeightScreen,
        trialType,
      }}
    />
  );
}
