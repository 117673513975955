import { useMediaQueries } from "@hooks/use-media-queries";
import { Box, Typography } from "@mui/material";
import { QueryParams } from "@router/route-params";
import { colors } from "@styles/common-colors";
import { useSearchParams } from "react-router-dom";

/** Renders header title and subtitle in create/activate account page */
export function CreateAccountHeader(): JSX.Element {
  const { isSmallHeightScreen } = useMediaQueries();
  const [searchParams] = useSearchParams();
  const companyParam = searchParams.get(QueryParams.company);

  return (
    <>
      <Box
        paddingTop={isSmallHeightScreen ? "0px" : "50px"}
        sx={{
          color: colors.gray850,
          fontSize: isSmallHeightScreen ? "25px" : "32px",
          lineHeight: isSmallHeightScreen ? "30px" : "44px",
          fontWeight: 600,
          letterSpacing: "-0.32px",
        }}
      >
        {companyParam ? "Activate your account" : "Create an account"}
      </Box>

      <Box
        sx={{
          paddingTop: isSmallHeightScreen ? "5px" : "10px",
          marginBottom: isSmallHeightScreen ? "20px" : "40px",
          fontSize: "14px",
          lineHeight: "18px",
          letterSpacing: "0px",
          color: colors.gray700,
        }}
      >
        {companyParam ? (
          <Typography
            fontSize={"14px"}
            color={colors.gray700}
            textAlign={"center"}
          >
            Complete your account to join{" "}
            <b>
              <var>{companyParam}</var>
            </b>
          </Typography>
        ) : (
          "Create an account to get free access to your workspace"
        )}
      </Box>
    </>
  );
}
