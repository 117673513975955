import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { App } from "@src/App";
import {
  MainRoutes,
  SignUpInvitedAccountRoutes,
  SignUpRoutes,
  SignUpTrialAccountRoutes,
} from "@router/route-params";
import { CustomErrorBoundary } from "@router/custom-error-boundary";
import { UlmPage } from "@pages/ulm/ulm-page";
import { CreateTrialAccount } from "@pages/ulm/signup/trial-account/create-trial-account";
import { CreateAccount } from "@pages/ulm/signup/create-account";
import { VerifyEmailPage } from "@pages/verify-email-page";
import { ResendEmailPage } from "@pages/resend-email-page";
import { PasswordRecovery } from "@pages/ulm/password/password-recovery";
import { ResetPassword } from "@pages/ulm/password/reset-password";
import { CreateWorkspace } from "@pages/ulm/signup/trial-account/create-workspace";
import { CreateAccountWrapper } from "@pages/ulm/signup/create-account-wrapper";
import { ActiveYourAccount } from "@pages/ulm/signup/active-your-account";
import { LogoutPage } from "@pages/ulm/logout-page";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} errorElement={<CustomErrorBoundary />}>
      <Route path={MainRoutes.login} element={<UlmPage />} />

      <Route path={MainRoutes.logout} element={<LogoutPage />} />

      {/* Sign up route: /signup */}
      <Route path={MainRoutes.signup} element={<CreateAccountWrapper />}>
        {/* Sign up page to redirect to /signup/trial */}
        <Route
          index
          element={
            <Navigate
              to={{
                pathname: SignUpRoutes.trial,
                search: window.location.search,
              }}
            />
          }
        />

        {/* Sign up page for invited members */}
        <Route path={SignUpRoutes.invited}>
          <Route index element={<ActiveYourAccount />} />

          <Route
            path={SignUpInvitedAccountRoutes.createAccount}
            element={<CreateAccount />}
          />
        </Route>

        {/* Sign up page for trial account */}
        <Route path={SignUpRoutes.trial}>
          <Route index element={<CreateTrialAccount />} />
          <Route
            path={SignUpTrialAccountRoutes.createAccount}
            element={<CreateAccount />}
          />
          <Route
            path={SignUpTrialAccountRoutes.createWorkspace}
            element={<CreateWorkspace />}
          />
        </Route>
      </Route>

      {/* Email verification route: /verifymail */}
      <Route path={MainRoutes.verifyEmail} element={<VerifyEmailPage />} />

      {/* Resend verification email route: /resendEmail */}
      <Route path={MainRoutes.resendEmail} element={<ResendEmailPage />} />

      {/* Password recovery route: /passwordRecovery */}
      <Route
        path={MainRoutes.passwordRecovery}
        element={<PasswordRecovery />}
      />

      {/* Reset password route: /resetPassword */}
      <Route path={MainRoutes.resetPassword} element={<ResetPassword />} />

      {/* Root: / */}
      <Route
        path={MainRoutes.root}
        element={
          <Navigate
            to={{
              pathname: MainRoutes.login,
              search: window.location.search,
            }}
          />
        }
      />
    </Route>
  )
);
