import {
  Box,
  Divider,
  InputAdornment,
  SxProps,
  TextField,
} from "@mui/material";
import { Checkmark3Icon } from "@faro-lotv/flat-ui";
import FaroExclamationIcon from "@assets/icons/exclamation-mark.svg?react";
import { colors } from "@styles/common-colors";
import { EServerIdentifier } from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { flagIcons } from "@pages/ulm/login/select-server";
import { useMemo } from "react";
import { serverOptions } from "@constants/ulm-constants";

export interface Props {
  /** Input value */
  value: string;

  /** Type of the `input` element */
  type?: React.InputHTMLAttributes<unknown>["type"];

  /** This prop helps users to fill forms faster, it's more like an autofill */
  autoComplete?: string;

  /** Name of the input field, used to get autofill feature */
  name?: string;

  /** Whether the value is validated */
  isValidValue?: boolean;

  /** Whether the input is in error state */
  isError?: boolean;

  /** Text to display when input is in error state */
  errorText?: string;

  /** Text to show user inside the input */
  placeholder: string;

  /** Function that triggers on every input value change */
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;

  /** Function that triggers on every key event */
  onKeyUp?(event: React.KeyboardEvent<HTMLInputElement>): void;

  /** Optional mui props pass to the component */
  sx?: SxProps;

  /** True if the text field should be disabled */
  isDisabled?: boolean;

  /** Server that was selected */
  serverIdentifier?: EServerIdentifier;
}

export function UlmTextField({
  value,
  isValidValue,
  isError,
  errorText,
  placeholder,
  onChange,
  onKeyUp,
  type,
  autoComplete,
  name,
  isDisabled = false,
  serverIdentifier,
  sx,
}: Props): JSX.Element {
  function getServerText(identifier: EServerIdentifier): string {
    const option = serverOptions.find(
      (option) => option.identifier === identifier
    );
    return option ? option.text : "";
  }

  const serverText = useMemo(() => {
    return serverIdentifier ? getServerText(serverIdentifier) : "";
  }, [serverIdentifier]);

  return (
    <TextField
      value={value}
      name={name}
      onChange={onChange}
      onKeyUp={onKeyUp}
      error={isError}
      helperText={isError ? errorText : null}
      fullWidth
      disabled={isDisabled}
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      sx={{
        marginBottom: isError ? "0px" : "3px",
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="start">
              {isValidValue && (
                <Checkmark3Icon sx={{ color: colors.blue500 }} />
              )}
              {isError && <FaroExclamationIcon />}
            </InputAdornment>
            {serverIdentifier && (
              <>
                <Divider orientation="vertical" flexItem />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    width: "192px",
                    px: "16px",
                    fontSize: "14px",
                  }}
                >
                  {flagIcons[serverIdentifier]}
                  {serverText}
                </Box>
              </>
            )}
          </>
        ),
      }}
      FormHelperTextProps={{
        sx: {
          marginBottom: "0px",
        },
      }}
    />
  );
}
