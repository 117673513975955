// This component was fully copied from the Sphere Dashboard repo:
// https://dev.azure.com/faro-connect/Stellar/_git/Dashboard?path=/src/components/common/sphere-tooltip.tsx
import { Box, BoxProps, Tooltip, TooltipProps } from "@mui/material";

/** The props of this tooltip are the same as the MUI Tooltip */
export interface SphereTooltipProps {
  /**
   * Any content that will be the activator of the tooltip.
   * Can be a button, icon, some text, etc.
   */
  children: JSX.Element | string;

  /** Required title to show inside the tooltip */
  title: TooltipProps["title"];

  /** Optional props to override the Box element that wraps all the tooltip content */
  component?: BoxProps["component"];

  /** Optional time to wait between the initial hover and showing the tooltip content */
  enterDelay?: TooltipProps["enterDelay"];

  /** Optional props to override the MUI Tooltip element. */
  tooltipProps?: Partial<Omit<TooltipProps, "children">>;

  /** Optional props to override the Box element that wraps all the tooltip content */
  boxProps?: BoxProps;

  /**
   * Optional flag to skip the wrapper component. As default the tooltip content is
   * wrapped in a Box to make sure the tooltip has a valid component that can hold a ref.
   * If disabled make sure that the component inside the tooltip can host a ref.
   */
  shouldSkipWrapper?: boolean;

  /**
   * Optional flag to show the tooltip, if false it won't wrap
   * the content in a tooltip and just return the children.
   * Default: true.
   */
  shouldShowTooltip?: boolean;

  /** ID to identify the tooltip. It is used for our automation framework playwright */
  dataTestId?: string;
}

/* Default time in ms to wait on hover to open the tooltip */
const DEFAULT_ENTER_DELAY = 600;

/**
 * React component for a tooltip that follows our design guidelines.
 */
export function SphereTooltip({
  children,
  title,
  component = "div",
  enterDelay = DEFAULT_ENTER_DELAY,
  tooltipProps,
  boxProps,
  shouldSkipWrapper = false,
  shouldShowTooltip = true,
  dataTestId,
}: SphereTooltipProps): JSX.Element {
  return (
    <Tooltip
      title={title}
      data-testid={dataTestId}
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      PopperProps={{
        // To hide the tooltip, keep the children wrapped in the component and just use the hidden prop.
        // Otherwise the parent component might behave different when switching from being wrapped.
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        hidden: !shouldShowTooltip,
      }}
      {...tooltipProps}
    >
      {/* If the children is a string we must put it inside a box wrapper, otherwise the tooltip
            does not have a component to attach to. */}
      {shouldSkipWrapper && typeof children !== "string" ? (
        children
      ) : (
        // Unfortunately there seem to be an error in the compiler that says
        // that this type is too complex to represent. Since it should be
        // just a compiler error and seems safe enough, ignore the error for now.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Safe to ignore
        // @ts-ignore
        <Box component={component} {...boxProps}>
          {children}
        </Box>
      )}
    </Tooltip>
  );
}
