import { LoadingButton } from "@mui/lab";
import { CircularProgress, SxProps } from "@mui/material";

import { colors } from "@styles/common-colors";

export interface Props {
  /** Button text */
  buttonText: string;

  /** Whether the button is disabled */
  isDisabled?: boolean;

  /** Whether the loading state is active */
  isLoading?: boolean;

  /** Button text when the loading state is active */
  isLoadingText?: string;

  /** Function that triggers when the button is clicked */
  onClick(event?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;

  /** Optional component style */
  sx?: SxProps;
}

export function UlmButton({
  buttonText,
  isDisabled = false,
  isLoading = false,
  isLoadingText = "",
  onClick,
  sx,
}: Props): JSX.Element {
  return (
    <LoadingButton
      disabled={isDisabled}
      disableRipple
      fullWidth
      onClick={onClick}
      variant="contained"
      loading={isLoading}
      loadingPosition="end"
      endIcon={
        isLoading ? (
          <CircularProgress role="progressbar" color="inherit" size={16} />
        ) : (
          // Added an empty fragment because otherwise mui throws an unexpected error:
          // https://github.com/mui/material-ui/issues/27586
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )
      }
      sx={{
        ...sx,
        textTransform: "none",
        height: "44px",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "-0.16px",
        backgroundColor: colors.blue500,
        boxShadow: "none",
        border: "solid 1px",
        borderColor: colors.blue600,
        "&.MuiLoadingButton-loading.Mui-disabled": {
          backgroundColor: colors.blue700,
          color: "white",
          borderColor: colors.blue700,
        },
        "&.Mui-disabled": {
          backgroundColor: "#8EB1F7",
          color: "white",
          borderColor: "#85A5E5",
        },
        "&:hover:not(.Mui-disabled)": {
          backgroundColor: colors.blue600,
          boxShadow: "none",
        },
        "&:active:not(.Mui-disabled)": {
          backgroundColor: colors.blue700,
          boxShadow: "none",
          borderColor: colors.blue700,
        },
      }}
    >
      {isLoading ? isLoadingText : buttonText}
    </LoadingButton>
  );
}
