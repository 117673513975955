/** List all the main routes that user can navigate */
export enum MainRoutes {
  /** The root page of the app which will be redirected to app route */
  root = "/",

  /** Login page */
  login = "login",

  /** Logout page */
  logout = "logout",

  /** Signup page */
  signup = "signup",

  /** Email verification page */
  verifyEmail = "verifymail",

  /** Resend verification email page */
  resendEmail = "resendEmail",

  /** Password recovery page */
  passwordRecovery = "passwordRecovery",

  /** Reset Password page */
  resetPassword = "resetPassword",
}

/** List of routes under signup regarding how user account can be created */
export enum SignUpRoutes {
  /** Sign up flow for a member who is invited in a company*/
  invited = "invited",

  /** Sign up flow for a user who wants to create trail account */
  trial = "trial",
}

export enum SignUpInvitedAccountRoutes {
  /** Sign up flow for a member who is invited in a company*/
  createAccount = "create-account",
}

export enum SignUpTrialAccountRoutes {
  /** Represents the route for users to create a new trial account */
  createAccount = "create-account",

  /** Represents the route for users to set up a workspace after creating a trial account. */
  createWorkspace = "create-workspace",
}

/** All the query  params that can be appended to the route */
export enum QueryParams {
  /** ID of the client App that uses the ULM */
  client = "client",

  /** Version of the client App that uses the ULM */
  version = "version",

  /** Encoded window parent URL when the ULM is embedded. It is only used by the Sphere Dashboard */
  parentUrl = "parent_url",

  /** Trial type identifier that can be used to request a trial with specific features */
  trialType = "trialType",

  /** Redirect URL after a successful Sphere 1 login */
  redirectUrlS1 = "redirectUrlS1",

  /** Redirect URL after a successful Sphere 2 COM login */
  redirectUrlS2Com = "redirectUrlS2Com",

  /** Redirect URL after a successful Sphere 2 EU login */
  redirectUrlS2Eu = "redirectUrlS2Eu",

  /** Use to hide the `Get started` text and link from the ULM login page */
  hideRegistration = "hideRegistration",

  /** Email to prefill the email input. If passed the ULM will request the email servers automatically */
  email = "email",

  /** Available server option. If passed this will be the only available server options */
  server = "server",

  /** Preselect server. If passed and if the user has active account there, it will automatically select that server */
  preselectedServer = "preselectedServer",

  /** Company name used when a user invited to a company */
  company = "company",

  /** Key used to verify the user email address */
  key = "key",

  /** SSO provider */
  provider = "provider",
}
