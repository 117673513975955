import { UlmClients } from "@constants/ulm-constants";
import { getErrorDisplayMarkup } from "@context-providers/error-handler/error-handler-utils";
import { ActiveServer } from "@custom-types/ulm-types";
import { useToast } from "@faro-lotv/flat-ui";
import { QueryParams } from "@router/route-params";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { BrowserUtils } from "@stellar/web-core";
import { useAppSelector } from "@store/store-helper";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import {
  isValidRedirectUrl,
  requestParentToRedirect,
} from "@utils/browser-utils";
import {
  getApiClientBasedOnServer,
  getSelectedServerUrl,
  getVisitedServerIdentifier,
  handleRedirectAfterLogin,
} from "@utils/ulm-utils";
import qs from "qs";
import { useSearchParams } from "react-router-dom";

interface UseSphereDashboardLogin {
  /** Contains user visited server identifier */
  visitedServerIdentifier: SphereDashboardAPITypes.EServerIdentifier | null;

  /** Login with email/password method to the selected server */
  loginWithEmail(props: LoginWithEmailProps): Promise<void>;

  /** Used to login with third party login provider */
  loginWithProvider(selectedServer: ActiveServer): void;
}

interface LoginWithEmailProps {
  /** The selected server to login */
  selectedServer: SphereDashboardAPITypes.EServerIdentifier;

  /** Sanitized email address entered by user */
  email: string;

  /** Password value to perform login request */
  password: string;
}

/**
 * Custom hook that used for the login operations
 *
 * @returns login Method to perform login request
 */
export function useSphereDashboardLogin(): UseSphereDashboardLogin {
  const [searchParams] = useSearchParams();
  const client = useAppSelector(ulmConfigClientSelector);
  const { openToast } = useToast();

  /** Visited server identifier based on the url user is currently visiting */
  const visitedServerIdentifier = getVisitedServerIdentifier();

  async function loginWithEmail({
    selectedServer,
    email,
    password,
  }: LoginWithEmailProps): Promise<void> {
    const credentials = qs.stringify({
      userId: email,
      password: password,
    });

    const coreApiClient = getApiClientBasedOnServer(selectedServer);
    await coreApiClient.V1.basicLogin(credentials);

    const redirectUrlParam =
      selectedServer === SphereDashboardAPITypes.EServerIdentifier.sphere2Us
        ? searchParams.get(QueryParams.redirectUrlS2Com)
        : searchParams.get(QueryParams.redirectUrlS2Eu);

    // Only use the parent url param if the selected server is the same as the visited server
    // and the parent url param is valid according to the redirect whitelist
    const parentUrlParam = BrowserUtils.readSearchParam(QueryParams.parentUrl);
    let parentUrl = null;
    if (
      visitedServerIdentifier === selectedServer &&
      parentUrlParam &&
      isValidRedirectUrl(parentUrlParam)
    ) {
      parentUrl = parentUrlParam;
    }

    // Give preference to the redirect URL param, then the parent URL param
    // If not provided then set to the default selected server URL
    const serverUrl =
      redirectUrlParam || parentUrl || getSelectedServerUrl(selectedServer);

    try {
      handleRedirectAfterLogin(serverUrl, client, selectedServer);
    } catch (error) {
      openToast({
        title: "Invalid login URL",
        message: getErrorDisplayMarkup(error),
        variant: "error",
      });
    }
  }

  function loginWithProvider(selectedServer: ActiveServer): void {
    try {
      const loginUrl = new URL(selectedServer.loginProvider.loginUrl);

      const redirectUrlParam =
        selectedServer.identifier ===
        SphereDashboardAPITypes.EServerIdentifier.sphere2Us
          ? searchParams.get(QueryParams.redirectUrlS2Com)
          : searchParams.get(QueryParams.redirectUrlS2Eu);

      // Only use the parent url param if the selected server is the same as the visited server
      // and the parent url param is valid according to the redirect whitelist
      const parentUrlParam = BrowserUtils.readSearchParam(
        QueryParams.parentUrl
      );
      let parentUrl = null;
      if (
        visitedServerIdentifier === selectedServer.identifier &&
        parentUrlParam &&
        isValidRedirectUrl(parentUrlParam)
      ) {
        parentUrl = parentUrlParam;
      }

      // Give preference to the redirect URL param, then the parent URL param
      // If not provided then set to the default selected server URL
      const redirectUrl =
        redirectUrlParam ||
        parentUrl ||
        getSelectedServerUrl(selectedServer.identifier);

      loginUrl.searchParams.set("redirectUrl", redirectUrl);

      // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
      // Instead of a simple redirect we need to send a window message so they redirect instead
      if (
        client === UlmClients.sphereDashboard ||
        client === UlmClients.sphereViewer
      ) {
        requestParentToRedirect(loginUrl);
      } else {
        BrowserUtils.redirectToUrl(loginUrl.href);
      }
    } catch (error) {
      openToast({
        title: "Invalid login URL",
        message: getErrorDisplayMarkup(error),
        variant: "error",
      });
    }
  }

  return {
    visitedServerIdentifier,
    loginWithEmail,
    loginWithProvider,
  };
}
