// eslint-disable-next-line no-restricted-imports -- The only place needed to initialize Logger
import { Logger } from "@stellar/web-core";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { HandledError } from "@context-providers/error-handler/error-handler-types";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { removeOne, setOne } from "@store/errors/errors-slice";
import { ErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";
import { runtimeConfig } from "@src/runtime-config";
import { ErrorPage } from "@pages/error-page";
import { errorsSelector } from "@store/errors/errors-selectors";
import { useToast } from "@faro-lotv/flat-ui";
import { getErrorDisplayMarkup } from "@context-providers/error-handler/error-handler-utils";
import { SanitizedHtmlTag } from "@context-providers/error-handler/sanitized-html-tag";

const dsn = runtimeConfig.features.sentryDSNKey;
const environment = runtimeConfig.appEnv;
const release = runtimeConfig.appVersion;

export function ErrorHandlerProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();
  const errors = useAppSelector(errorsSelector);
  const errorsCount = useRef<number>(errors.length);
  const [pageError, setPageError] = useState<HandledError | null>(null);

  /** Initializes logger */
  useEffect(() => {
    if (dsn) {
      Logger.init({
        dsn,
        environment,
        release,
      });
    }
  }, []);

  /** Logs error to Sentry */
  const logError = useCallback((error: HandledError): void => {
    if (!dsn) {
      return;
    }

    const title = error.title;
    const baseError = error.error;

    if (typeof baseError === "string" || baseError instanceof Error) {
      Logger.logError(title, { error: baseError });
    } else {
      Logger.logError(title, { payload: { baseError } });
    }
  }, []);

  /** Shows an error toast */
  const showErrorToast = useCallback(
    (error: HandledError): void => {
      openToast({
        key: error.id,
        title: error.title,
        message: (
          <SanitizedHtmlTag markup={getErrorDisplayMarkup(error.error)} />
        ),
        variant: "error",
      });
    },
    [openToast]
  );

  /**
   * Detects if there are new errors in the store.
   * If there are selects the latest error and:
   * - it logs it to sentry
   * - it removes it from the store
   * - if it's a "toast" error it shows the toast
   * - if it's a "page" error it sets it in the state
   */
  useEffect(() => {
    if (errors.length > errorsCount.current) {
      const newError = errors[errors.length - 1];
      logError(newError);
      dispatch(removeOne(newError.id));
      newError.uiType === "toast" && showErrorToast(newError);
      newError.uiType === "page" && setPageError(newError);
    }

    errorsCount.current = errors.length;
  }, [dispatch, errors, logError, showErrorToast]);

  /** Adds error to the store */
  const handleError = useCallback(
    (error: HandledError): void => {
      dispatch(setOne(error));
    },
    [dispatch]
  );

  return (
    <ErrorHandlerContext.Provider
      value={{
        handleError,
      }}
    >
      {pageError ? (
        <ErrorPage error={pageError} close={() => setPageError(null)} />
      ) : (
        children
      )}
    </ErrorHandlerContext.Provider>
  );
}
