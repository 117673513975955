import { UlmClientType } from "@custom-types/ulm-types";
import { Box } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import { useMemo } from "react";

/** List of ULM clients that should see the HoloBuilder Intro */
const CLIENTS_WHITELIST: UlmClientType[] = [
  "default",
  "sphereDashboard",
  "sphereViewer",
];

/**
 * Simple component that shows a message to HoloBuilder users indicating how to log in.
 * The component only shows to whitelisted ULM clients or if the ULM client is not defined.
 */
export function HoloBuilderIntro(): JSX.Element | undefined {
  const client = useAppSelector(ulmConfigClientSelector);

  const shouldShow = useMemo(() => {
    return client === null || CLIENTS_WHITELIST.includes(client);
  }, [client]);

  if (!shouldShow) {
    return;
  }

  return (
    <Box
      component="div"
      sx={{
        padding: "16px",
        backgroundColor: "#F5F9FF",
        border: "1px solid #DCE0E5",
        fontSize: "14px",
        fontStyle: "normal",
        marginTop: "34px",
      }}
    >
      {/* Title */}
      <Box component="span" fontWeight="800">
        <Box component="var" fontStyle="normal">
          HoloBuilder{" "}
        </Box>
        <Box component="span">user?</Box>
      </Box>

      {/* Message */}
      <Box component="div" marginTop="5px">
        You can now log in directly from here using the same login credentials
        and third party services you are used to. Just type in your account
        email address.
      </Box>
    </Box>
  );
}
