import { AcknowledgementText } from "@components/common/acknowledgement-text";
import {
  PageIdentifier,
  SSOProviders,
} from "@components/ulm/SSO-providers/sso-providers";
import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { Box, Divider, Typography } from "@mui/material";
import { CreateAccountHeader } from "@pages/ulm/signup/create-account-header";
import { colors } from "@styles/common-colors";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { CreateAccountContext } from "@pages/ulm/signup/trial-account/create-trial-accounts-types";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import { QueryParams } from "@router/route-params";
import { getVisitedServerIdentifier } from "@utils/ulm-utils";
import { useEffect } from "react";

/**
 * The ActiveYourAccount component is designed to support the activation of user accounts by providing options to
 * sign up with a password or use Single Sign-On (SSO) providers.
 */
export function ActiveYourAccount(): JSX.Element | null {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get(QueryParams.email);

  const { navigateToInviteSignup } = useAppNavigation();

  const { updateAccountInfo, accountInfo } =
    useOutletContext<CreateAccountContext>();

  function onClickContinueButton(): void {
    const fullPathWithQuery = `${location.pathname}${location.search}`;

    updateAccountInfo({ previousLocationRef: fullPathWithQuery });
    navigateToInviteSignup();
  }

  useEffect(() => {
    const visitedServerIdentifier = getVisitedServerIdentifier();

    if (visitedServerIdentifier) {
      updateAccountInfo({ selectedServer: visitedServerIdentifier });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!email) {
    return null;
  }

  return (
    <UlmContainer>
      <CreateAccountHeader />

      <Box marginTop={"40px"} width={"100%"}>
        {/* Email input */}
        <FaroInputLabel title="Email" />
        <UlmTextField
          value={email}
          type="email"
          name="email"
          autoComplete="email"
          isDisabled={true}
          placeholder="name@company.com"
          serverIdentifier={accountInfo.selectedServer}
        />
      </Box>
      {/* Button */}
      <UlmButton
        buttonText="Sign up with a password"
        onClick={onClickContinueButton}
        sx={{
          marginTop: "8px",
        }}
      />

      <Divider sx={{ width: "100%", mt: "32px" }}>
        <Typography color={colors.gray600} paddingX={"75px"}>
          or sign up with
        </Typography>
      </Divider>

      <SSOProviders pageIdentifier={PageIdentifier.invitedAccount} />

      <AcknowledgementText />
    </UlmContainer>
  );
}
