import { useMediaQueries } from "@hooks/use-media-queries";
import { Link, Typography } from "@mui/material";

/** Renders terms of use and privacy policy text while sign up */
export function AcknowledgementText(): JSX.Element {
  const { isSmallHeightScreen } = useMediaQueries();

  return (
    <Typography
      variant="caption"
      sx={{
        marginTop: "auto",
        marginBottom: isSmallHeightScreen ? "30px" : "50px",
        textAlign: "center",
      }}
    >
      By proceeding, you agree to the{" "}
      <Link
        target="_blank"
        href="https://www.farosphere.com/home/legal/terms-of-service"
        underline="none"
      >
        terms of use
      </Link>{" "}
      and acknowledge you’ve read our{" "}
      <Link
        target="_blank"
        href="https://www.holobuilder.com/privacy-policy-docs/"
        underline="none"
      >
        privacy policy
      </Link>
    </Typography>
  );
}
