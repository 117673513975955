import { Link } from "@mui/material";
import { colors } from "@styles/common-colors";
import { Link as RouterLink } from "react-router-dom";
import ArrowLeftIcon from "@assets/icons/arrow-left_24px.svg?react";

export interface Props {
  /** Route */
  route: string | null;
}

export function UlmBackButton({ route }: Props): JSX.Element {
  return (
    <Link
      component={RouterLink}
      to={route || "/"}
      underline="hover"
      sx={{
        color: colors.blue500,
        fontSize: "14px",
        fontWeight: 600,
        alignItems: "center",
        display: "flex",
        gap: "10px",
        py: "32px",
      }}
    >
      <ArrowLeftIcon color={colors.blue500} /> Return to previous screen
    </Link>
  );
}
