import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { isApiError } from "@custom-types/type-guards";
import { useToast } from "@faro-lotv/flat-ui";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { Box } from "@mui/material";
import { QueryParams } from "@router/route-params";
import { colors } from "@styles/common-colors";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

/** Renders component to verify the email after a successful sign-up */
export function VerifyEmailPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const coreApiClient = useCoreApiClient();
  const { openToast } = useToast();
  const { navigateToResendEmail } = useAppNavigation();
  const { handleError } = useErrorHandlerContext();

  const [description, setDescription] = useState<string>(
    "Verifying email address..."
  );
  const [shouldShowResendButton, setShouldShowResendButton] =
    useState<boolean>(false);

  const verifyEmail = useCallback(async () => {
    const errorTitle = "Failed to verify the email address.";
    const key = searchParams.get(QueryParams.key);

    // Early exit the function and show an error toast if the verification key is undefined
    if (!key) {
      openToast({
        title: errorTitle,
        message: "The email verification key was not provided.",
        variant: "error",
      });
      return;
    }

    try {
      await coreApiClient.V1.SDB.verifyEmail({ key });

      setDescription("Your email address has been successfully verified.");

      openToast({
        title: "Your email address has been successfully verified.",
        variant: "success",
      });

      setShouldShowResendButton(false);
    } catch (error) {
      // If the error status code is 404 it just means that the email verification key is not valid
      // in this case we should not log the error to sentry and just show the error toast.
      if (isApiError(error) && error.status === StatusCodes.NOT_FOUND) {
        openToast({
          title: errorTitle,
          message: error.message,
          variant: "error",
        });
      } else {
        handleError({
          id: `verifyEmail-${Date.now().toString()}`,
          title: errorTitle,
          error,
          uiType: "toast",
        });
      }

      setDescription(
        "Your email address could not be verified. " +
          "Note that verification links can only be used once. " +
          "You can also request a new verification email by clicking on the button below."
      );
      setShouldShowResendButton(true);
    }
  }, [coreApiClient.V1.SDB, handleError, openToast, searchParams]);

  /** Verifies the email only once on page load */
  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- request email verification only once
  }, []);

  return (
    <UlmContainer>
      <Box
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Email Address Verification
      </Box>

      <Box
        sx={{
          marginTop: "40px",
          fontSize: "14px",
          color: colors.gray850,
        }}
      >
        {description}
      </Box>

      {shouldShowResendButton && (
        <UlmButton
          buttonText="Resend Email"
          onClick={navigateToResendEmail}
          sx={{
            marginTop: "20px",
          }}
        />
      )}
    </UlmContainer>
  );
}
