import { useCallback, useMemo } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { runtimeConfig } from "@src/runtime-config";
import { UlmClientType } from "@custom-types/ulm-types";
import { redirectClient } from "@utils/client-utils";
import { useSearchParams } from "react-router-dom";
import { QueryParams } from "@router/route-params";
import { UlmClients } from "@constants/ulm-constants";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

/** Hook return type */
type Sphere1LoginFn = () => Promise<void>;

interface Props {
  /** Email address that prefills the login form */
  loginEmail: string;

  /** ULM client ID */
  client: UlmClientType | null;
}

/**
 * Custom hook that handles navigating to Sphere 1 login with the prefilled email address
 */
export function useSphere1Login({ loginEmail, client }: Props): Sphere1LoginFn {
  const { handleError } = useErrorHandlerContext();
  const [searchParams] = useSearchParams();
  const redirectUrlParam = searchParams.get(QueryParams.redirectUrlS1);

  /**
   * URL to redirect after a successful login. By default it redirects to the Sphere 1 landing page.
   * If available, use instead the URL passed in the query param.
   */
  const redirectUrl = redirectUrlParam
    ? redirectUrlParam
    : runtimeConfig.urls.sphere10Url;

  /**
   * Auth0 client ID value. By default it uses the client ID of the Sphere 1 application set in Auth0 Admin Dashboard.
   * For the ULM client "streamFocus" we will use the client ID value of the "Stream" app set in Auth0 Admin Dashboard.
   */
  const auth0ClientId =
    client === UlmClients.streamFocus
      ? runtimeConfig.features.streamAppAuth0ClientId
      : runtimeConfig.features.sphere10Auth0ClientId;

  // Create memoized auth0 client
  const auth0Client = useMemo(() => {
    const client = new Auth0Client({
      domain: runtimeConfig.features.sphere10Auth0Domain,
      clientId: auth0ClientId,
      authorizationParams: {
        audience: "farosphere-api",
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        redirect_uri: redirectUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        login_hint: loginEmail,
        // Set "offline_access" scope to also get the refresh token:
        // https://auth0.com/docs/secure/tokens/refresh-tokens/get-refresh-tokens
        scope: "openid profile email offline_access",
      },
    });

    return client;
  }, [auth0ClientId, loginEmail, redirectUrl]);

  const handleSphere1Login = useCallback(async () => {
    try {
      if (!auth0Client) {
        throw new Error("Missing Sphere 1.0 auth0Client");
      }

      await auth0Client.loginWithRedirect({
        openUrl: (url: string) => {
          /**
           * In case the ULM redirects to a mobile app after login, for example to Stream, the mobile
           * app also needs the auth0 client "code_verifier" in order to successfully exchange the
           * successful-login "code" for access and refresh tokens. Currently the package we use to
           * handle auth0 workflows (@auth0/auth0-spa-js) does not provide an option to also pass the
           * code verifier as query param of the successful login redirect URL.
           * We need to manually pass it like this:
           * - Get the code verifier from the browsers sessionStorage. It's set automatically there by
           * the auth0 client once instantiated.
           * - Append the code verifier as query param to the successful login redirect URL
           */
          const auth0sessionStorage = sessionStorage.getItem(
            `a0.spajs.txs.${auth0ClientId}`
          );

          const missingCodeVerifierMsg = "Auth0 code verifier not available";
          if (!auth0sessionStorage) {
            throw new Error(missingCodeVerifierMsg);
          }

          const auth0sessionStorageParsed = JSON.parse(auth0sessionStorage);
          const codeVerifierKey = "code_verifier";
          const codeVerifier = auth0sessionStorageParsed[codeVerifierKey];

          if (typeof codeVerifier !== "string") {
            throw new Error(missingCodeVerifierMsg);
          }

          const redirectUrlObject = new URL(redirectUrl);
          redirectUrlObject.searchParams.append(codeVerifierKey, codeVerifier);

          const loginUrl = new URL(url);
          loginUrl.searchParams.set("redirect_uri", redirectUrlObject.href);

          redirectClient({
            client,
            redirectUrl: loginUrl.href,
          });
        },
      });
    } catch (error) {
      handleError({
        id: `auth0Login-${Date.now().toString()}`,
        title: "An error happened during the Auth0 login",
        error,
        uiType: "toast",
      });
    }
  }, [auth0Client, auth0ClientId, client, handleError, redirectUrl]);

  return handleSphere1Login;
}
