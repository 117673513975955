import { ApiError } from "@custom-types/ulm-types";

export function isApiError(error: unknown): error is ApiError {
  if (
    error &&
    typeof error === "object" &&
    "status" in error &&
    "message" in error &&
    "code" in error
  ) {
    return true;
  }
  return false;
}
