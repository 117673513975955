import { ApiClient, SphereDashboardAPITypes } from "@stellar/api-logic";
import { BrowserUtils } from "@stellar/web-core";
import {
  EServerDisplayText,
  ActiveServer,
  UlmClientFlagType,
  UlmClientType,
  EServerDisplayTextVariant,
  ServerWithActiveStatus,
} from "@custom-types/ulm-types";
import {
  UlmClientFlags,
  UlmClients,
  UlmTrialTypes,
} from "@constants/ulm-constants";
import {
  UlmConfigState,
  initialState,
} from "@store/ulm-config/ulm-config-slice";
import { QueryParams } from "@router/route-params";
import { runtimeConfig } from "@src/runtime-config";
import { redirectClient } from "@utils/client-utils";
import { requestParentToRedirect } from "@utils/browser-utils";
import { getApiClient } from "@api/core-api/core-api-utls";

/** Max height in pixels for screens to be considered small */
export const SmallScreenMaxHeight = "800px";

/**
 * @returns the text to display to the user for a given server
 * @param identifier Id of the server
 */
export function getUserServerText(
  identifier: SphereDashboardAPITypes.EServerIdentifier
): EServerDisplayText {
  switch (identifier) {
    case SphereDashboardAPITypes.EServerIdentifier.sphere1:
      return EServerDisplayText.sphere1;
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Us:
      return EServerDisplayText.sphere2Us;
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Eu:
      return EServerDisplayText.sphere2Eu;
  }
}

/**
 * @returns the text to display to the user for a given server
 * @param identifier Id of the server
 */
export function getUserServerTextVariant(
  identifier: SphereDashboardAPITypes.EServerIdentifier
): EServerDisplayTextVariant {
  switch (identifier) {
    case SphereDashboardAPITypes.EServerIdentifier.sphere1:
      return EServerDisplayTextVariant.sphere1;
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Us:
      return EServerDisplayTextVariant.sphere2Us;
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Eu:
      return EServerDisplayTextVariant.sphere2Eu;
  }
}

/**
 * @returns the url of the selected server
 * @param identifier Id of the server
 */
export function getSelectedServerUrl(
  identifier: SphereDashboardAPITypes.EServerIdentifier
): string {
  switch (identifier) {
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Us:
      return runtimeConfig.urls.dashboard20ComUrl;
    case SphereDashboardAPITypes.EServerIdentifier.sphere2Eu:
      return runtimeConfig.urls.dashboard20EuUrl;
    case SphereDashboardAPITypes.EServerIdentifier.sphere1:
      return runtimeConfig.urls.sphere10Url;
  }
}

/**
 * @returns True if the ULM client is a string and is included in the ULM clients list
 */
export function isValidUlmClient(
  client: string | null
): client is UlmClientType {
  if (!client) {
    return false;
  }

  return Object.values<string>(UlmClients).includes(client);
}

/**
 * @returns True if the ULM client config Flag is of type UlmClientFlagType
 */
export function isValidUlmClientConfigFlag(
  flag: string
): flag is UlmClientFlagType {
  return Object.values<string>(UlmClientFlags).includes(flag);
}

/**
 * @returns True if the server identifier string is of type EServerIdentifier
 */
export function isValidServerIdentifier(
  identifier: string
): identifier is SphereDashboardAPITypes.EServerIdentifier {
  return Object.values<string>(
    SphereDashboardAPITypes.EServerIdentifier
  ).includes(identifier);
}

/**
 * Gets an array of strings an return only the ones that are valid ULM client config flags
 */
export function validateUlmClientConfigFlags(
  flags: string[]
): UlmClientFlagType[] {
  const validatedFlags: UlmClientFlagType[] = [];

  flags.forEach((flag) => {
    if (isValidUlmClientConfigFlag(flag)) {
      validatedFlags.push(flag);
    }
  });

  return validatedFlags;
}

/**
 * Reads the ULM client config flags from the env. vars, validates them and returns them as an array
 */
export function getUlmClientConfigFlags(
  client: UlmClientType,
  ulmClientsFlags: Record<string, string>
): UlmClientFlagType[] {
  // Iterate over all the client flags available in the env variables
  for (const [key, value] of Object.entries(ulmClientsFlags)) {
    // hasOwnProperty check is used to ensure that we only iterate over the ulmClientsFlags own properties.
    // eslint-disable-next-line no-prototype-builtins
    if (ulmClientsFlags.hasOwnProperty(key)) {
      // Flags are separated by commas in the env variables
      const flags = value.split(",");

      // The first flag is always the client ID.
      if (flags[0] === client) {
        // Remove client ID since is not a configuration flag
        flags.shift();

        // Remove possible duplicated flags
        const sanitizedFlags = [...new Set(flags)];

        return validateUlmClientConfigFlags(sanitizedFlags);
      }
    }
  }

  return [];
}

/**
 * Returns the ULM config State for a given client and config flags
 */
export function getUlmConfigState(
  client: UlmClientType,
  flags: UlmClientFlagType[]
): UlmConfigState {
  // Start with the default config
  const ulmConfig = { ...initialState };

  // Early exit if there are no flags to set
  if (!flags.length) {
    return ulmConfig;
  }

  // Set client ID
  ulmConfig.client = client;

  // Set configuration.
  // It is up to the developers to not set flags that conflict with each other.
  // Ex. Do not set `directSignup` and `sfSignup` for a single client.
  flags.forEach((flag) => {
    switch (flag) {
      case UlmClientFlags.directSignup:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isDirectSignup: true,
        };
        break;
      case UlmClientFlags.sfSignup:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isDirectSignup: false,
        };
        break;
      case UlmClientFlags.sphere1Login:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isSphere1LoginEnabled: true,
        };
        break;
      case UlmClientFlags.noSphere1Login:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isSphere1LoginEnabled: false,
        };
        break;
      case UlmClientFlags.sphere2Login:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isSphere2LoginEnabled: true,
        };
        break;
      case UlmClientFlags.noSphere2Login:
        ulmConfig.configFlags = {
          ...ulmConfig.configFlags,
          isSphere2LoginEnabled: false,
        };
        break;
    }
  });

  // Set the trial type if applicable
  switch (client) {
    case UlmClients.connect:
      ulmConfig.configFlags = {
        ...ulmConfig.configFlags,
        trialType: UlmTrialTypes["cloud-processing-faro-connect"],
      };
      break;
    default:
      ulmConfig.configFlags = {
        ...ulmConfig.configFlags,
        trialType: null,
      };
  }

  return ulmConfig;
}

/**
 * Returns the ULM config State based on the con the "client" query parameter.
 *
 * If first validates the "client" value. If it's not valid it's uses the default client.
 * Then it gets the configuration flags from the env variables for the given client .
 * Finally it gets the ULM configuration state from the configuration flags
 */
export function getUlmConfig(): UlmConfigState {
  const searchParams = new URLSearchParams(window.location.search);
  const clientParam = searchParams.get(QueryParams.client);
  const client = isValidUlmClient(clientParam)
    ? clientParam
    : UlmClients.default;

  const ulmClientsFlags = runtimeConfig.features.ulmClientsFlags;
  const flags = getUlmClientConfigFlags(client, ulmClientsFlags);
  const ulmConfig = getUlmConfigState(client, flags);

  return ulmConfig;
}

interface FilterActiveServersProps {
  /** Servers where user has an active account */
  activeServers: ServerWithActiveStatus[];

  /** ULM client configuration flags */
  configFlags: UlmConfigState["configFlags"];

  /** Server Option. If passed it will be the only available server option */
  serverOption?: SphereDashboardAPITypes.EServerIdentifier;
}

/**
 * @returns the active servers filtered by the available login options
 *
 * @param activeServers servers with active status for the user email
 * @param configFlags Config flags from the client options
 * @param serverOption Server Option that if defined it will be the only available server option
 */
export function filterActiveServers({
  activeServers,
  configFlags,
  serverOption,
}: FilterActiveServersProps): ServerWithActiveStatus[] {
  const availableServers: SphereDashboardAPITypes.EServerIdentifier[] = [];

  if (serverOption) {
    // If the serverOptions is defined set that option only
    availableServers.push(serverOption);
  } else {
    // Set available servers according to the ULM client config flags
    if (configFlags.isSphere1LoginEnabled) {
      availableServers.push(SphereDashboardAPITypes.EServerIdentifier.sphere1);
    }

    if (configFlags.isSphere2LoginEnabled) {
      availableServers.push(
        SphereDashboardAPITypes.EServerIdentifier.sphere2Us
      );
      availableServers.push(
        SphereDashboardAPITypes.EServerIdentifier.sphere2Eu
      );
    }
  }

  // Filter out active servers not available due to the ULM client config options
  return activeServers.filter((server) =>
    availableServers.includes(server.identifier)
  );
}

/** Returns api client based on the selected server */
export function getApiClientBasedOnServer(
  selectedServer: SphereDashboardAPITypes.EServerIdentifier
): ApiClient {
  const apiBaseUrl =
    selectedServer === SphereDashboardAPITypes.EServerIdentifier.sphere2Us
      ? runtimeConfig.urls.apiBaseComUrl
      : runtimeConfig.urls.apiBaseEuUrl;

  return getApiClient(apiBaseUrl);
}

/** Returns true if the passed server uses third party login provider */
export function isSelectedServerUseThirdPartyLogin(
  selectedServer: ActiveServer
): boolean {
  return (
    selectedServer.loginProvider.identifier !==
    SphereDashboardAPITypes.ELoginProviderIdentifier.email
  );
}

/**
 * Handles the redirection after a successful login
 * @param url redirect URL
 * @param client ULM client
 * @param selectedServer Selected server
 * @throws {Error} if the passed url is invalid
 */
export function handleRedirectAfterLogin(
  url: string,
  client: UlmClientType | null,
  selectedServer: SphereDashboardAPITypes.EServerIdentifier
): void {
  const urlObject = BrowserUtils.constructUrl({
    url,
  });

  // Throw error if there is no valid URL
  if (!urlObject) {
    throw new Error("Invalid Sphere 2.0 login redirect URL");
  }

  // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
  // Instead of a simple redirect we need to send a window message so they redirect instead
  if (
    client === UlmClients.sphereDashboard ||
    client === UlmClients.sphereViewer
  ) {
    requestParentToRedirect(urlObject);
  } else {
    // Redirect to the url with core api
    const apiUrl =
      selectedServer === SphereDashboardAPITypes.EServerIdentifier.sphere2Us
        ? runtimeConfig.urls.apiBaseComUrl
        : runtimeConfig.urls.apiBaseEuUrl;

    const redirectUrl = `${apiUrl}/redirectTo?redirectUrl=${encodeURIComponent(
      urlObject.href
    )}`;

    BrowserUtils.redirectToUrl(redirectUrl);
  }
}

interface RedirectToAnotherServerLoginPage {
  /** Server that user already selected */
  selectedServer: SphereDashboardAPITypes.EServerIdentifier;

  /** Sanitized email address */
  email: string;

  /** Client which is using the ULM */
  client: UlmClientType | null;
}

/** Redirect users to another SXG server with login info in query params */
export function redirectToAnotherServerLoginPage({
  selectedServer,
  email,
  client,
}: RedirectToAnotherServerLoginPage): void {
  // Use "set" to overwrite email and selectedServer query params
  // to avoid duplicate query params with same key
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(QueryParams.email, email);
  searchParams.set(QueryParams.preselectedServer, selectedServer);

  // Remove "parent_url" query param since there won't be a parent window anymore
  searchParams.delete(QueryParams.parentUrl);

  const ulmOrigin =
    selectedServer === SphereDashboardAPITypes.EServerIdentifier.sphere2Us
      ? runtimeConfig.urls.sphereEntryPageComUrl
      : runtimeConfig.urls.sphereEntryPageEuUrl;

  redirectClient({
    client,
    redirectUrl: `${ulmOrigin}?${searchParams.toString()}`,
  });
}

/**
 * Returns the visited server identifier based on the current ULM instance
 */
export function getVisitedServerIdentifier(): SphereDashboardAPITypes.EServerIdentifier | null {
  const appEnv = runtimeConfig.appEnv;
  const domainIdentifier = appEnv.split("-").shift();

  switch (domainIdentifier) {
    case "com":
      return SphereDashboardAPITypes.EServerIdentifier.sphere2Us;
    case "eu":
      return SphereDashboardAPITypes.EServerIdentifier.sphere2Eu;
    default:
      return null;
  }
}
