import { Link } from "@mui/material";
import { runtimeConfig } from "@src/runtime-config";

/**
 * The `ContactSupport` component providing them with a direct link to contact support via email.
 */
export function ContactSupport(): JSX.Element {
  return (
    <Link href={`mailto:${runtimeConfig.mailSupportAddress}`} underline="none">
      {runtimeConfig.mailSupportAddress}
    </Link>
  );
}
