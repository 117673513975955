import { useMediaQuery } from "@mui/material";
import { SmallScreenMaxHeight } from "@utils/ulm-utils";

interface UseMediaQueries {
  /** Whether the screen height is shorter than the SmallScreenMaxHeight value */
  isSmallHeightScreen: boolean;
}

/** Custom hook that returns media queries used along the app */
export function useMediaQueries(): UseMediaQueries {
  const isSmallHeightScreen = useMediaQuery(
    `(max-height: ${SmallScreenMaxHeight})`
  );

  return { isSmallHeightScreen };
}
