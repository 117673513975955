import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";
import { useToast } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/material";
import { colors } from "@styles/common-colors";
import { isValidEmail } from "@utils/email-utils";
import { useCallback, useEffect, useMemo, useState } from "react";

/** Renders component to resend a verification email */
export function ResendEmailPage(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { openToast } = useToast();
  const { handleError } = useErrorHandlerContext();

  const [description, setDescription] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailInputError, setIsEmailInputError] = useState<boolean>(false);
  const [isResendingEmail, setIsResendingEmail] = useState<boolean>(false);
  const [shouldShowInputAndButton, setShouldShowInputAndButton] =
    useState<boolean>(true);

  const sanitizedEmail = useMemo(() => {
    return email.trim();
  }, [email]);

  const isValidEmailFormat = useMemo(() => {
    return isValidEmail(sanitizedEmail);
  }, [sanitizedEmail]);

  const resendEmail = useCallback(async () => {
    if (!isValidEmailFormat) {
      setIsEmailInputError(true);
      return;
    }

    setIsResendingEmail(true);

    try {
      await coreApiClient.V3.SDB.resendVerificationMail(sanitizedEmail);

      setShouldShowInputAndButton(false);

      setDescription(
        "Verification email successfully sent. Please check your email inbox for confirmation."
      );

      openToast({
        title: "Verification email successfully sent.",
        variant: "success",
      });
    } catch (error) {
      handleError({
        id: `resendVerificationEmail-${Date.now().toString()}`,
        title: "Failed to resend a new verification email.",
        error,
        uiType: "toast",
      });
    }

    setIsResendingEmail(false);
  }, [
    coreApiClient.V3.SDB,
    handleError,
    isValidEmailFormat,
    openToast,
    sanitizedEmail,
  ]);

  const onEmailKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (event.key === "Enter") {
        resendEmail();
      }
    },
    [resendEmail]
  );

  /** Resets the email input error state if the input it's cleared or if it has a valid email format */
  useEffect(() => {
    if (sanitizedEmail === "" || isValidEmailFormat) {
      setIsEmailInputError(false);
    }
  }, [isValidEmailFormat, sanitizedEmail]);

  return (
    <UlmContainer>
      <Box
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Resend Verification Email
      </Box>

      <Box
        sx={{
          marginTop: "40px",
          fontSize: "14px",
          color: colors.gray850,
        }}
      >
        {description}
      </Box>

      {shouldShowInputAndButton && (
        <>
          <Stack
            sx={{
              marginTop: "20px",
              width: "100%",
            }}
          >
            <FaroInputLabel title="Email" />
            <UlmTextField
              value={email}
              type="email"
              name="email"
              autoComplete="email"
              isValidValue={isValidEmailFormat}
              isError={isEmailInputError}
              errorText={"Enter a valid email address"}
              placeholder={"Enter your email"}
              onChange={(event) => setEmail(event.target.value)}
              onKeyUp={onEmailKeyUp}
              isDisabled={isResendingEmail}
            />
          </Stack>

          <UlmButton
            buttonText="Resend Email"
            isDisabled={sanitizedEmail === ""}
            isLoading={isResendingEmail}
            isLoadingText="Resending..."
            onClick={resendEmail}
            sx={{
              marginTop: "20px",
            }}
          />
        </>
      )}
    </UlmContainer>
  );
}
