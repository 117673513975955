export const colors = {
  pureWhite: "#FFFFFF",

  gray50: "#F8F9FA",
  gray100: "#F0F2F5",
  gray200: "#E0E4EB",
  gray300: "#C2C9D6",
  gray400: "#A3AEC2",
  gray500: "#8592AD",
  gray600: "#667799",
  gray700: "#525F7A",
  gray800: "#3D485C",
  gray850: "#363C4D",
  gray950: "#20252E",

  black: "#000000",

  red50: "#FFEBEB",
  red100: "#FFEBEB",
  red500: "#FA2424",
  red550: "#E50505",
  red600: "#E50505",
  red700: "#B30404",

  green50: "#E8FCF3",
  green700: "#0D8040",

  blue50: "#EFF4FE",
  blue100: "#DEE8FD",
  blue400: "#4E86F3",
  blue500: "#1F65F0",
  blue600: "#0E4ECC",
  blue700: "#0B3C9D",

  yellow100: "#FFF8E5",
  yellow600: "#E8A600",
  yellow650: "#CC9200",

  orange500: "#F06E1D",
  orange600: "#D6621A",
};

/** Defines some constants in the colors named by how are they used. */
export const colorConst = {
  placeholder: colors.gray500,
  normalFont: colors.gray850,
};
