import { createTheme } from "@mui/material";
import { DEFAULT_FONT_FAMILY, inputPlaceholderSx } from "@styles/common-styles";
import { colors } from "@styles/common-colors";
import { FaroThemeOptions } from "@faro-lotv/flat-ui";

/**
 * Overrides the default Mui theme
 */
export const mainTheme = createTheme({
  ...FaroThemeOptions,
  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
    caption: {
      color: colors.gray700,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            marginBottom: "24px",
            "&.Mui-disabled": {
              backgroundColor: colors.gray100,
            },
            "&.Mui-error fieldset": {
              border: 2,
              borderColor: colors.red600,
            },
            ...inputPlaceholderSx,
          }),
        input: {
          padding: "8px !important",
          "&:hover": {
            cursor: "pointer",
          },
          "&:hover input::placeholder": {
            color: colors.gray850,
          },
          "&:focus input": {
            cursor: "pointer",
          },
          "&:autofill": {
            WebkitBoxShadow: "0 0 0 30px white inset !important",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ...inputPlaceholderSx,
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px",
              "& fieldset": {
                borderColor: colors.gray500,
              },
              "&:hover fieldset": {
                borderColor: colors.gray850,
              },
              "&.Mui-focused fieldset": {
                border: 2,
                borderColor: colors.blue500,
              },
              "&.Mui-focused input": {
                cursor: "text",
              },
              "& input::placeholder": {
                fontSize: "14px",
                lineHeight: "16px",
                fontStyle: "italic",
                color: colors.gray500,
              },
              "&.Mui-focused input::placeholder": {
                color: `${colors.gray500}66`,
              },
            },
            "& .MuiFormHelperText-root.Mui-error": {
              fontSize: "12px",
              lineHeight: "18px",
              fontStyle: "italic",
              fontWeight: 600,
              color: colors.red600,
            },
          }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: "-15px",
          marginBottom: "10px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.gray850,
          color: colors.pureWhite,
        },
      },
    },
  },
});
