import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { colorConst } from "@styles/common-colors";

/** Default font size for input elements like text fields and selects */
export const DEFAULT_INPUT_FONT_SIZE = "12px";

/** Default font size for text */
export const DEFAULT_TEXT_FONT_SIZE = "12px";

/** Font family to be used to display content normal or bold */
export const DEFAULT_FONT_FAMILY: CSSProperties["fontFamily"] =
  "Open Sans, sans-serif";

/** Sx style for placeholders */
export const placeholderSx: SxProps = {
  fontSize: "14px",
  color: colorConst.placeholder,
  fontStyle: "italic",
};

/** Sx style to have placeholder in any input element  */
export const inputPlaceholderSx: SxProps = {
  "& .MuiInputBase-input::placeholder": {
    ...placeholderSx,
  },
};
