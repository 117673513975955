import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";
import { LoadingScreen } from "@faro-lotv/flat-ui";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useEffect } from "react";

/** Performs logout operation with showing a loading state */
export function LogoutPage(): JSX.Element {
  const { navigateToLogin } = useAppNavigation();
  const coreApiClient = useCoreApiClient();
  const { handleError } = useErrorHandlerContext();

  // Logout and navigate to login page
  useEffect(() => {
    async function logoutAndRedirect(): Promise<void> {
      try {
        await coreApiClient.V3.SDB.logoutUser();
        navigateToLogin();
      } catch (error) {
        handleError({
          id: `logoutUser-${Date.now().toString()}`,
          title:
            "An error occurred while logging out. Reload the page to try again.",
          error,
          uiType: "toast",
        });
      }
    }

    logoutAndRedirect();
  }, [coreApiClient, handleError, navigateToLogin]);

  return <LoadingScreen />;
}
