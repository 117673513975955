import { Box } from "@mui/material";
import { runtimeConfig } from "@src/runtime-config";
import { FaroButtonSSO } from "@components/ulm/SSO-providers/faro-sso-button";
import GoogleIcon from "@assets/icons/google.svg?react";
import AppleIcon from "@assets/icons/apple.svg?react";
import BlueBeamIcon from "@assets/icons/blueBeam.svg?react";
import ProcoreIcon from "@assets/icons/procore.svg?react";
import AutodeskIcon from "@assets/icons/autodesk.svg?react";
import FaroIcon from "@assets/icons/faro.svg?react";
import { CoreAPITypes } from "@stellar/api-logic";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { ReactElement } from "react";
import { useAppSelector } from "@store/store-helper";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import { redirectWithSSOProvider } from "@components/ulm/SSO-providers/SSO-providers-utils";
import { useSphereDashboardLogin } from "@hooks/use-sphere-dashboard-login";
import { convertProviderToEnum } from "@components/ulm/SSO-providers/SSO-providers-utils";

export enum PageIdentifier {
  /** Represents a page where users can create a trial account. */
  trialAccount = "trialAccount",

  /** Represents a page where users are invited to create an account. */
  invitedAccount = "invitedAccount",
}

interface Props {
  /** Identifies the context where the SSO provider buttons will be rendered. */
  pageIdentifier: PageIdentifier;
}

const providerMap: Record<
  string,
  { icon: ReactElement; providerName: string }
> = {
  [CoreAPITypes.EOAuthProvider.google]: {
    icon: <GoogleIcon />,
    providerName: "Google",
  },
  [CoreAPITypes.EOAuthProvider.apple]: {
    icon: <AppleIcon />,
    providerName: "Apple",
  },
  [CoreAPITypes.EOAuthProvider.bluebeam]: {
    icon: <BlueBeamIcon />,
    providerName: "BlueBeam",
  },
  [CoreAPITypes.EOAuthProvider.procore]: {
    icon: <ProcoreIcon />,
    providerName: "Procore",
  },
  [CoreAPITypes.EOAuthProvider.autodesk]: {
    icon: <AutodeskIcon />,
    providerName: "Autodesk",
  },
};

/**
 * Renders a list of Single Sign-On (SSO) provider buttons, allowing users to log in using various OAuth providers.
 */
export function SSOProviders({ pageIdentifier }: Props): JSX.Element {
  const providers = runtimeConfig.oauth.providers;
  const { navigateToTrialCreateWorkspace } = useAppNavigation();
  const client = useAppSelector(ulmConfigClientSelector);
  const { visitedServerIdentifier } = useSphereDashboardLogin();

  async function handleLoginWithProvider(
    provider: CoreAPITypes.EOAuthProvider
  ): Promise<void> {
    if (pageIdentifier === PageIdentifier.trialAccount) {
      navigateToTrialCreateWorkspace(provider);
    }

    if (
      pageIdentifier === PageIdentifier.invitedAccount &&
      visitedServerIdentifier
    ) {
      redirectWithSSOProvider(provider, client, visitedServerIdentifier);
    }
  }

  function renderProviderButtons(): JSX.Element | null {
    const buttons = providers
      .filter((provider) => provider !== CoreAPITypes.EOAuthProvider.faroSphere)
      .map((provider) => {
        const providerEnum = convertProviderToEnum(provider);
        const providerInfo = providerMap[providerEnum];

        return providerInfo ? (
          <Box flex={{ xs: "1 1 100%", md: "1" }} key={provider}>
            <FaroButtonSSO
              description={providerInfo.providerName}
              icon={providerInfo.icon}
              onClick={() => handleLoginWithProvider(providerEnum)}
            />
          </Box>
        ) : null;
      });

    if (buttons.length === 0) {
      return null;
    }

    return (
      <Box display="flex" flexWrap="wrap" gap={2}>
        {buttons}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width={"100%"}
      my={"32px"}
    >
      <Box display="flex" flexWrap="wrap" gap={2}>
        {providers.includes("faro-sphere") && (
          <Box flex={1}>
            <FaroButtonSSO
              label="Faro"
              icon={<FaroIcon />}
              sx={{ justifyContent: "left" }}
              onClick={() =>
                handleLoginWithProvider(CoreAPITypes.EOAuthProvider.faroSphere)
              }
            />
          </Box>
        )}
      </Box>

      {renderProviderButtons()}
    </Box>
  );
}
