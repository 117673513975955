import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Link, Stack } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { colors } from "@styles/common-colors";
import { isValidEmail } from "@utils/email-utils";
import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import { UlmButton } from "@components/ulm/ulm-button";
import {
  isSelectedServerUseThirdPartyLogin,
  redirectToAnotherServerLoginPage,
} from "@utils/ulm-utils";
import { UlmContainer } from "@components/ulm/ulm-container";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useSphere1Login } from "@hooks/ulm/use-sphere1-login";
import { useMediaQueries } from "@hooks/use-media-queries";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  ulmConfigClientSelector,
  ulmConfigFlagsSelector,
} from "@store/ulm-config/ulm-config-selector";
import {
  fetchEmailServers,
  resetFetchedServers,
  setHasCheckPrefillEmail,
  setHasSelectedPreselectedServer,
} from "@store/login/login-slice";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useSphereDashboardLogin } from "@hooks/use-sphere-dashboard-login";
import { SelectServer } from "@pages/ulm/login/select-server";
import { isApiError } from "@custom-types/type-guards";
import { OpenToastOptions, useToast } from "@faro-lotv/flat-ui";
import { UlmPasswordField } from "@components/ulm/ulm-password-field";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import {
  activeServersSelector,
  hasCheckPrefillEmailSelector,
  hasSelectedPreselectedServerSelector,
  isFetchingServersSelector,
  prefillEmailSelector,
  preselectedServerSelector,
  shouldHideRegistrationSelector,
} from "@store/login/login-selector";
import { redirectClient } from "@utils/client-utils";
import { shouldUseSphere1ForStreamApp } from "@utils/servers-utils";
import { HoloBuilderIntro } from "@components/ulm/holobuilder-intro";
import { Link as RouterLink } from "react-router-dom";
import { ContactSupport } from "@components/ulm/contact-support";
import { getErrorDisplayMarkup } from "@context-providers/error-handler/error-handler-utils";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";

/** Text to show in `Start free trial` tooltip */
const START_FREE_TRIAL_TEXT =
  "Access our 21-day free trial to explore Sphere XG. " +
  "See just how easy it is to navigate & collaborate.";

/**
 * Unified Login Mask (ULM): login
 */
export function UlmPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const client = useAppSelector(ulmConfigClientSelector);
  const configFlags = useAppSelector(ulmConfigFlagsSelector);
  const prefillEmail = useAppSelector(prefillEmailSelector);
  const hasCheckPrefillEmail = useAppSelector(hasCheckPrefillEmailSelector);
  const preselectedServer = useAppSelector(preselectedServerSelector);
  const hasSelectedPreselectedServer = useAppSelector(
    hasSelectedPreselectedServerSelector
  );
  const shouldHideRegistration = useAppSelector(shouldHideRegistrationSelector);
  const activeServers = useAppSelector(activeServersSelector);
  const isFetchingServers = useAppSelector(isFetchingServersSelector);
  const { isSmallHeightScreen } = useMediaQueries();
  const coreApiClient = useCoreApiClient();
  const { navigateToSignupStartFreeTrial } = useAppNavigation();
  const { visitedServerIdentifier, loginWithEmail, loginWithProvider } =
    useSphereDashboardLogin();
  const { openToast, closeToast } = useToast();
  const { handleError } = useErrorHandlerContext();

  // Whether a backend request for the login is ongoing.
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Whether a redirection outside the ULM is ongoing.
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  // Raw email input value
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // Whether the value in the email input has an invalid email format
  const [isEmailInputError, setIsEmailInputError] = useState<boolean>(false);

  // The selected server identifier
  const [selectedServerIdentifier, setSelectedServerIdentifier] =
    useState<SphereDashboardAPITypes.EServerIdentifier | null>(null);

  // Sanitized email input value
  const sanitizedEmail = useMemo(() => {
    return email.trim();
  }, [email]);

  // Whether the sanitized email input value has a valid email format
  const isValidEmailFormat = useMemo(() => {
    return isValidEmail(sanitizedEmail);
  }, [sanitizedEmail]);

  /**
   * Whether the server selector component should be shown:
   * - The user has more than one active servers and
   * - (special case for the "streamFocus client") there is no automatic selection of Sphere1 server
   */
  const shouldShowServerSelector = useMemo(() => {
    return (
      activeServers &&
      activeServers.length > 1 &&
      !shouldUseSphere1ForStreamApp(client, activeServers)
    );
  }, [activeServers, client]);

  // Whether the continue button should be shown
  const shouldShowContinueButton = useMemo(() => {
    return !(activeServers && activeServers.length > 0);
  }, [activeServers]);

  // The selected server
  const selectedServer = useMemo(() => {
    if (activeServers && activeServers.length > 0) {
      return activeServers.find(
        (server) => server.identifier === selectedServerIdentifier
      );
    }
  }, [activeServers, selectedServerIdentifier]);

  // Show password field if selected server uses login with email and the email input is valid
  // and there is no redirection
  const shouldShowPasswordField = useMemo(() => {
    return (
      isValidEmailFormat &&
      selectedServer?.loginProvider?.identifier ===
        SphereDashboardAPITypes.ELoginProviderIdentifier.email &&
      selectedServer.identifier === visitedServerIdentifier
    );
  }, [isValidEmailFormat, selectedServer, visitedServerIdentifier]);

  // True iff the preselected Server is one of the active servers
  const isValidPreselectedServer = useMemo(() => {
    if (!activeServers || !preselectedServer) {
      return false;
    }

    const activeServersIds = activeServers.map((server) => server.identifier);
    return activeServersIds.includes(preselectedServer);
  }, [activeServers, preselectedServer]);

  const handleSphere1Login = useSphere1Login({
    loginEmail: sanitizedEmail,
    client,
  });

  /** Determine the forgot password link based on the selected server */
  const forgotPasswordLink = useCallback(
    (text: string) => {
      const redirectionServer =
        selectedServerIdentifier ?? visitedServerIdentifier;

      // Early return if redirectionServer is not selected or if it's Sphere 1 server
      if (
        !redirectionServer ||
        redirectionServer === SphereDashboardAPITypes.EServerIdentifier.sphere1
      ) {
        return undefined;
      }

      const encodedEmail = encodeURIComponent(email);

      return (
        <Link
          component={RouterLink}
          to={`/passwordRecovery?email=${encodedEmail}`}
          underline="hover"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          {text}
        </Link>
      );
    },
    [email, selectedServerIdentifier, visitedServerIdentifier]
  );

  // Triggered on every change in the email input
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    setEmail(event.target.value);

    // Reset password field, selected server, fetched servers and warnings
    setPassword("");
    setSelectedServerIdentifier(null);
    dispatch(resetFetchedServers());
    closeToast();
  }

  // If the `enter` key was pressed then do the same as when the continue button is clicked
  function onEmailKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.stopPropagation();
    if (event.key === "Enter") {
      getUserServers();
    }
  }

  // If the `enter` key was pressed then do the same as when the login button is clicked
  function onPasswordKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.stopPropagation();
    if (event.key === "Enter") {
      onLogInClick();
    }
  }

  // Triggered when the login button is clicked
  async function onLogInClick(
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event && event.stopPropagation();

    // Early return if selected server identifier and password is not available
    if (!selectedServerIdentifier || !password.length || !isValidEmailFormat) {
      return;
    }

    setIsLoading(true);

    try {
      await loginWithEmail({
        selectedServer: selectedServerIdentifier,
        email: sanitizedEmail,
        password,
      });
    } catch (error) {
      if (isApiError(error)) {
        switch (error.code) {
          case "lockedAccount":
            openToast({
              title:
                "Please confirm your email address to continue using Sphere XG!",
              variant: "error",
            });
            break;
          case "userCredentialsIncorrect":
            openToast({
              title:
                "Incorrect password. Please ensure the password is entered correctly and try again.",
              variant: "error",
            });
            break;
        }
      } else {
        handleError({
          id: `loginWithEmail-${Date.now().toString()}`,
          title: "Failed to login.",
          error,
          uiType: "toast",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  // Requests the servers for the sanitized email value and sets the active servers
  const getUserServers = useCallback(async (): Promise<void> => {
    // close toast messages
    closeToast();

    // If the email format is invalid do an early exit
    if (!isValidEmailFormat) {
      // Set email input in error state
      setIsEmailInputError(true);
      return;
    }

    await dispatch(
      fetchEmailServers({
        coreApiClient,
        email: sanitizedEmail,
      })
    );
  }, [closeToast, isValidEmailFormat, dispatch, coreApiClient, sanitizedEmail]);

  function handleSignupClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.stopPropagation();

    // Check ULM config flags
    // If direct signup navigate to signup page. Otherwise redirect to SalesForce free trial page
    if (configFlags.isDirectSignup) {
      navigateToSignupStartFreeTrial();
    } else {
      try {
        redirectClient({
          client,
          redirectUrl: "https://www.holobuilder.com/faro-sphere-xg-trial",
        });
      } catch (error) {
        openToast({
          title: "Redirection failed",
          message: getErrorDisplayMarkup(error),
          variant: "error",
        });
      }
    }
  }

  // Resets the email input error state if the input it's cleared or if it has a valid email format
  useEffect(() => {
    if (sanitizedEmail === "" || isValidEmailFormat) {
      setIsEmailInputError(false);
    }
  }, [isValidEmailFormat, sanitizedEmail]);

  // Handles the automatic email check if there is a valid prefilled email
  useEffect(() => {
    if (prefillEmail && !hasCheckPrefillEmail) {
      if (!sanitizedEmail) {
        setEmail(prefillEmail);
      } else {
        if (isValidEmailFormat) {
          getUserServers();
        } else {
          setIsEmailInputError(true);
        }
        dispatch(setHasCheckPrefillEmail(true));
      }
    }
  }, [
    dispatch,
    getUserServers,
    hasCheckPrefillEmail,
    prefillEmail,
    sanitizedEmail,
    isValidEmailFormat,
  ]);

  // Show account banner if there is no active server with the email
  useEffect(() => {
    if (activeServers && activeServers.length === 0) {
      openToast({
        title: "No account found for this email",
        variant: "warning",
      });
    }
  }, [activeServers, openToast]);

  // Handles the automatic server selection if there is only one active server
  useEffect(() => {
    if (activeServers && activeServers.length === 1) {
      const serverIdentifier = activeServers[0].identifier;
      setSelectedServerIdentifier(serverIdentifier);
    }
  }, [activeServers]);

  // Handles the automatic server selection if there is a valid preselected server
  useEffect(() => {
    if (preselectedServer && !hasSelectedPreselectedServer && activeServers) {
      // Only set the preselected if it's valid and if there are more than one active servers
      if (isValidPreselectedServer && activeServers.length > 1) {
        setSelectedServerIdentifier(preselectedServer);
      }
      dispatch(setHasSelectedPreselectedServer(true));
    }
  }, [
    preselectedServer,
    isValidPreselectedServer,
    activeServers,
    hasSelectedPreselectedServer,
    dispatch,
  ]);

  /**
   * Handles the automatic selection of Sphere 1 server for the ULM client "streamFocus".
   * It will automatically select Sphere 1 server when the user has the Sphere 1 server and
   * only one of the Sphere 2 servers (either EU or US).
   */
  useEffect(() => {
    if (shouldUseSphere1ForStreamApp(client, activeServers)) {
      setSelectedServerIdentifier(
        SphereDashboardAPITypes.EServerIdentifier.sphere1
      );
    }
  }, [activeServers, client]);

  /**
   * Handles all redirects that can happen after a server is selected.
   * The order of the checks is important. Be mindful before changing them. Current order:
   * 1. Sphere 1/Legacy login (not in used but available)
   * 2. SSO login providers for Sphere XG. Re redirection can be to both servers.
   * 3. Email/password login for Sphere XG but in a different server from the current one
   */
  useEffect(() => {
    if (!selectedServer) {
      return;
    }

    const redirectToast: OpenToastOptions = {
      title: "You are being redirected...",
      variant: "neutral",
    };

    // Redirect to the Sphere 1/Legacy login
    if (
      selectedServer.identifier ===
      SphereDashboardAPITypes.EServerIdentifier.sphere1
    ) {
      openToast(redirectToast);
      setIsRedirecting(true);
      handleSphere1Login();
      return;
    }

    // SSO login providers for Sphere XG
    if (isSelectedServerUseThirdPartyLogin(selectedServer)) {
      openToast(redirectToast);
      setIsRedirecting(true);
      loginWithProvider(selectedServer);
      return;
    }

    // Email/password login for Sphere XG but in a different server from the current one
    if (selectedServer.identifier !== visitedServerIdentifier) {
      openToast(redirectToast);
      setIsRedirecting(true);
      redirectToAnotherServerLoginPage({
        selectedServer: selectedServer.identifier,
        email: sanitizedEmail,
        client,
      });
      return;
    }
  }, [
    client,
    loginWithProvider,
    openToast,
    sanitizedEmail,
    selectedServer,
    visitedServerIdentifier,
    handleSphere1Login,
  ]);

  return (
    <UlmContainer>
      <>
        {/* Log in title */}
        <Box
          paddingTop={isSmallHeightScreen ? "20px" : "100px"}
          sx={{
            color: colors.gray850,
            fontSize: "32px",
            lineHeight: "44px",
            fontWeight: 600,
            letterSpacing: "-0.32px",
          }}
        >
          Log in
        </Box>

        {/* Start free trial */}
        {!shouldHideRegistration && (
          <Box
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0px",
              color: colors.gray700,
              display: "flex",
              alignItems: "center",
            }}
          >
            New to Sphere XG?
            <SphereTooltip
              title={START_FREE_TRIAL_TEXT}
              shouldSkipWrapper
              tooltipProps={{
                PopperProps: {
                  sx: {
                    maxWidth: "215px",
                  },
                },
              }}
            >
              <Link
                component="button"
                underline="none"
                onClick={handleSignupClick}
                sx={{
                  marginLeft: "5px",
                  color: colors.blue500,
                }}
              >
                Start free trial.
              </Link>
            </SphereTooltip>
          </Box>
        )}

        {/* HoloBuilder user? */}
        <HoloBuilderIntro />

        {/* Email input */}
        <Stack
          sx={{
            width: "100%",
            marginTop: shouldHideRegistration ? "74px" : "40px",
          }}
        >
          <FaroInputLabel title="Email" />
          <UlmTextField
            value={email}
            type="email"
            name="email"
            autoComplete="email"
            isValidValue={isValidEmailFormat}
            isError={isEmailInputError}
            errorText={"Enter a valid email format"}
            placeholder={"Enter your email"}
            onChange={onChange}
            onKeyUp={onEmailKeyUp}
            isDisabled={isFetchingServers || isLoading || isRedirecting}
          />
        </Stack>

        {/* Continue Button to confirm email input */}
        <UlmButton
          buttonText="Continue"
          isDisabled={sanitizedEmail === "" || isRedirecting}
          isLoading={isFetchingServers}
          isLoadingText="Verifying"
          onClick={getUserServers}
          sx={{
            marginTop: "8px",
            display: shouldShowContinueButton ? "inline-flex" : "none",
          }}
        />

        {/* Server selector */}
        {shouldShowServerSelector && activeServers?.length && (
          <SelectServer
            activeServers={activeServers}
            selectedServer={selectedServerIdentifier}
            onSelectServer={setSelectedServerIdentifier}
            isDisabled={isLoading || isRedirecting}
          />
        )}

        {/* Password input */}
        <Stack
          sx={{
            width: "100%",
            // Password field is hidden by css to get the browsers autocomplete
            display: shouldShowPasswordField ? "inline-flex" : "none",
          }}
        >
          <UlmPasswordField
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyUp={onPasswordKeyUp}
            helpComponent={
              shouldShowPasswordField
                ? forgotPasswordLink("Forgot password?")
                : null
            }
            isDisabled={isRedirecting}
          />
        </Stack>

        {/* Login Button */}
        <UlmButton
          buttonText="Log in"
          isDisabled={password === "" || isRedirecting}
          isLoading={isLoading}
          isLoadingText="Logging In"
          onClick={onLogInClick}
          sx={{
            marginTop: "8px",
            display: shouldShowPasswordField ? "inline-flex" : "none",
          }}
        />

        {/* Support */}
        <Box
          component="div"
          sx={{
            marginBottom: "10px",
            textAlign: "center",
            fontSize: "14px",
            flex: 1,
            alignContent: "end",
            marginTop: "20px",
          }}
        >
          Can’t log in? Contact <ContactSupport />
        </Box>
      </>
    </UlmContainer>
  );
}
