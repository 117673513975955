import { UlmClients } from "@constants/ulm-constants";
import { UlmClientType } from "@custom-types/ulm-types";
import { runtimeConfig } from "@src/runtime-config";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { BrowserUtils } from "@stellar/web-core";
import { requestParentToRedirect } from "@utils/browser-utils";

/**
 * Converts a string representing an OAuth provider into the corresponding enum value.
 *
 * This function maps a given provider string to its corresponding value in the `CoreAPITypes.EOAuthProvider` enum.
 * It ensures that the provider string is case-insensitive by converting it to lowercase before attempting to map it.
 * If the provider string does not match any known provider, an error is thrown.
 */
export function convertProviderToEnum(
  provider: string
): CoreAPITypes.EOAuthProvider {
  const providerLowerCase = provider.toLowerCase();

  const providerMapping: Record<string, CoreAPITypes.EOAuthProvider> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "faro-sphere": CoreAPITypes.EOAuthProvider.faroSphere,
    ...CoreAPITypes.EOAuthProvider,
  };

  if (providerLowerCase in providerMapping) {
    return providerMapping[providerLowerCase];
  }

  throw new Error(`Invalid OAuth provider: ${provider}`);
}

/**
 * Redirects the user to the appropriate SSO (Single Sign-On) provider's OAuth URL
 * based on the provided parameters. This method constructs the correct redirect URL
 * for the SSO process and handles the redirection logic depending on the client's context.
 */
export async function redirectWithSSOProvider(
  provider: CoreAPITypes.EOAuthProvider,
  client: UlmClientType | null,
  visitedServerIdentifier: SphereDashboardAPITypes.EServerIdentifier
): Promise<void> {
  const apiBaseUrl =
    visitedServerIdentifier ===
    SphereDashboardAPITypes.EServerIdentifier.sphere2Us
      ? runtimeConfig.urls.apiBaseComUrl
      : runtimeConfig.urls.apiBaseEuUrl;

  const redirectUrl = new URL(`${apiBaseUrl}/oauth?provider=${provider}`);

  redirectUrl.searchParams.set(
    "redirectUrl",
    runtimeConfig.urls.sphereDashboardUrl
  );

  // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
  // Instead of a simple redirect we need to send a window message so they redirect instead
  if (
    client === UlmClients.sphereDashboard ||
    client === UlmClients.sphereViewer
  ) {
    requestParentToRedirect(redirectUrl);
  } else {
    BrowserUtils.redirectToUrl(redirectUrl.href);
  }
}
