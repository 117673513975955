import { Typography, Stack } from "@mui/material";
import { UlmContainer } from "@components/ulm/ulm-container";
import { colors } from "@styles/common-colors";
import EmailIcon from "@assets/icons/email_60px.svg?react";
import { ContactSupport } from "@components/ulm/contact-support";

interface Props {
  /** The recipient's email address to which the password reset link has been sent. */
  email: string;
}

/**
 * A confirmation message explaining to the user that a password reset link
 * has been sent to the provided email address.
 */
export function CheckEmail({ email }: Props): JSX.Element {
  return (
    <UlmContainer>
      <Typography
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Check your email
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          color: colors.gray700,
        }}
      >
        {email}
      </Typography>

      <Stack
        sx={{
          width: "106px",
          height: "106px",
          background: colors.gray50,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          marginTop: "25px",
        }}
      >
        <EmailIcon />
      </Stack>

      <Typography
        sx={{
          color: colors.gray700,
          fontSize: "16px",
          fontWeight: 600,
          marginTop: "25px",
        }}
      >
        We've sent a reset link to your email address.
      </Typography>

      <Typography
        sx={{
          color: colors.gray700,
          fontSize: "16px",
          fontWeight: 400,
          marginTop: "25px",
          textAlign: "center",
        }}
      >
        If you don't receive the email within a few moments, please check your
        spam folder or contact <ContactSupport />
      </Typography>
    </UlmContainer>
  );
}
