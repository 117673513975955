// CssBaseline needs to be imported before all other styles to avoid errors
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { IReactChildrenOnly } from "@custom-types/types";
import { runtimeConfig } from "@src/runtime-config";
import { useUlmConfig } from "@hooks/ulm/use-ulm-config";
import { mainTheme } from "@styles/theme";
import { ToastProvider } from "@faro-lotv/flat-ui";
import { Localization } from "@utils/localization";
import { useTrackPageLoad } from "@hooks/use-track-page-load";
import { ErrorHandlerProvider } from "@context-providers/error-handler/error-handler-provider";
import { ErrorBoundary } from "@context-providers/error-boundary/error-boundary";

/**
 * All the context providers should be added here to make them available across the whole application.
 *
 * @returns all the react context providers and it's initialization.
 */
function AllContextProviders({ children }: IReactChildrenOnly): JSX.Element {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <ToastProvider>
        <ErrorHandlerProvider>
          <ErrorBoundary>{children}</ErrorBoundary>
        </ErrorHandlerProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

/**
 * Entry point deciding on the structure of the app
 */
export function App(): JSX.Element {
  // Log app version at start
  // eslint-disable-next-line no-console -- It is ok to show the version in the console
  console.log(`App version: ${runtimeConfig.appVersion}`);

  // Initialize ULM configuration
  useUlmConfig();

  // Initializes the tracking of the user in the entry page API
  useTrackPageLoad();

  /** Initialize the Localization feature with the key provided in runtimeConfig */
  if (runtimeConfig.features.localizeKey) {
    Localization.init(runtimeConfig.features.localizeKey);
  }

  return (
    <AllContextProviders>
      <div className="App" data-testid="homepage">
        <Box component="div" sx={{ display: "flex", height: "100vh" }}>
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          <Outlet />
        </Box>
      </div>
    </AllContextProviders>
  );
}
