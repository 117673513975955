import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UlmClientType, UlmTrialType } from "@custom-types/ulm-types";

/** Interface for the ULM login and signup options configuration */
export interface UlmConfigState {
  /** client ID */
  client: UlmClientType | null;

  /** Collection of config flags */
  configFlags: {
    /** Whether the signup workflow should go directly in the ULM or through the SalesForce HB Trial page */
    isDirectSignup: boolean;

    /** Whether the Sphere 1 logion should be available */
    isSphere1LoginEnabled: boolean;

    /** Whether the Sphere 2 logion should be available */
    isSphere2LoginEnabled: boolean;

    /** Registration trial type */
    trialType: UlmTrialType | null;
  };
}

/** Initial ULM configuration state */
export const initialState: UlmConfigState = {
  client: null,
  configFlags: {
    isDirectSignup: true,
    isSphere1LoginEnabled: false,
    isSphere2LoginEnabled: true,
    trialType: null,
  },
};

/**
 * Slice to access the ULM configuration state
 */
const ulmConfigSlice = createSlice({
  name: "ulmConfig",
  initialState,
  reducers: {
    setUlmConfigState(state, action: PayloadAction<UlmConfigState>) {
      state.client = action.payload.client;
      state.configFlags = { ...action.payload.configFlags };
    },

    resetUlmConfigState: () => initialState,
  },
});

export const { setUlmConfigState, resetUlmConfigState } =
  ulmConfigSlice.actions;

export const ulmConfigReducer = ulmConfigSlice.reducer;
