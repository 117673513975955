import { createContext, useContext } from "react";
import { HandledError } from "@context-providers/error-handler/error-handler-types";

interface ErrorHandlerContextProps {
  /** Callback that handles an error */
  handleError: (error: HandledError) => void;
}

export const ErrorHandlerContext =
  createContext<ErrorHandlerContextProps | null>(null);

/**
 * @returns the error handler context
 * @throws {Error} if is not used within an ErrorHandlerProvider
 */
export function useErrorHandlerContext(): ErrorHandlerContextProps {
  const context = useContext(ErrorHandlerContext);

  if (!context) {
    throw new Error(
      "useErrorHandlerContext() has to be used within an ErrorHandlerProvider."
    );
  }

  return context;
}
