import { PropsWithChildren } from "react";
import { Box, Stack } from "@mui/material";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import { useMediaQueries } from "@hooks/use-media-queries";
import { NavLink } from "react-router-dom";
import { resetLoginState } from "@store/login/login-slice";
import { useAppDispatch } from "@store/store-helper";

/**
 * Unified Login Mask (ULM) container
 */
export function UlmContainer({ children }: PropsWithChildren): JSX.Element {
  const { isSmallHeightScreen } = useMediaQueries();
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        width={440}
        minWidth={200}
        margin={"10px"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          width={"inherit"}
          sx={{
            paddingTop: isSmallHeightScreen ? "20px" : "75px",
          }}
        >
          <NavLink
            to={"/login"}
            onClick={() => {
              dispatch(resetLoginState());
            }}
          >
            <FaroSphereLogo
              style={{
                width: "150px",
                minHeight: "80px",
              }}
            />
          </NavLink>

          {children}
        </Stack>
      </Box>
    </Box>
  );
}
