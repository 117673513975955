import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { BaseCoreApiClientProps } from "@store/store-types";

/** Interface for the login state */
export interface LoginState {
  /** prefill email taken from the query params (if passed) */
  prefillEmail?: string;

  /** Whether the prefill email check has been carried out */
  hasCheckPrefillEmail?: boolean;

  /** Server option taken from the query params (if passed and valid) */
  serverOption?: SphereDashboardAPITypes.EServerIdentifier;

  /** Preselected server option taken from the query params (if passed and valid) */
  preselectedServer?: SphereDashboardAPITypes.EServerIdentifier;

  /** Whether the preselected server has already been selected */
  hasSelectedPreselectedServer?: boolean;

  /** Whether the "Start free trial" should be hidden from the login page */
  shouldHideRegistration?: boolean;

  /** Fetched servers */
  fetchedServers?: SphereDashboardAPITypes.Server[];

  /** Whether the servers are being fetched from the backend */
  isFetchingServers?: boolean;
}

/** Initial state */
export const initialState: LoginState = {};

interface FetchEmailServersProps extends BaseCoreApiClientProps {
  /** The email used to check the servers*/
  email: string;
}

export const fetchEmailServers = createAsyncThunk<
  SphereDashboardAPITypes.Server[],
  FetchEmailServersProps
>("login/fetchEmailServers", async ({ coreApiClient, email }) => {
  const { servers } = await coreApiClient.V3.SDB.getUserCheck(email);
  return servers;
});

/**
 * Slice to access the login state
 */
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginState(state, action: PayloadAction<LoginState>) {
      const keys = Object.keys(action.payload);
      keys.forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state as any)[key] = (action.payload as any)[key];
      });
    },

    setPrefillEmail(state, action: PayloadAction<string>) {
      state.prefillEmail = action.payload;
    },

    setHasCheckPrefillEmail(state, action: PayloadAction<boolean>) {
      state.hasCheckPrefillEmail = action.payload;
    },

    setServerOption(
      state,
      action: PayloadAction<SphereDashboardAPITypes.EServerIdentifier>
    ) {
      state.serverOption = action.payload;
    },

    setPreselectedServer(
      state,
      action: PayloadAction<SphereDashboardAPITypes.EServerIdentifier>
    ) {
      state.preselectedServer = action.payload;
    },

    setHasSelectedPreselectedServer(state, action: PayloadAction<boolean>) {
      state.hasSelectedPreselectedServer = action.payload;
    },

    setShouldHideRegistration(state, action: PayloadAction<boolean>) {
      state.shouldHideRegistration = action.payload;
    },

    resetFetchedServers(
      state,
      action: PayloadAction<LoginState["fetchedServers"]>
    ) {
      state.fetchedServers = undefined;
    },

    resetLoginState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmailServers.pending, (state, action) => {
        state.isFetchingServers = true;
      })
      .addCase(fetchEmailServers.fulfilled, (state, action) => {
        state.fetchedServers = action.payload;
        state.isFetchingServers = false;
      })
      .addCase(fetchEmailServers.rejected, (state, action) => {
        state.isFetchingServers = false;
      });
  },
});

export const {
  setLoginState,
  setPrefillEmail,
  setHasCheckPrefillEmail,
  setServerOption,
  setPreselectedServer,
  setHasSelectedPreselectedServer,
  setShouldHideRegistration,
  resetFetchedServers,
  resetLoginState,
} = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
