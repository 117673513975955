import { UlmClients } from "@constants/ulm-constants";
import {
  ActiveServer,
  ServerWithActiveStatus,
  UlmClientType,
} from "@custom-types/ulm-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getUserServerText, getUserServerTextVariant } from "@utils/ulm-utils";

/** Order in which we want to display the servers: Sphere XG COM, Sphere XG EU and Sphere 1/legacy */
const serverOrder: {
  [key in SphereDashboardAPITypes.EServerIdentifier]: number;
} = {
  [SphereDashboardAPITypes.EServerIdentifier.sphere2Us]: 0,
  [SphereDashboardAPITypes.EServerIdentifier.sphere2Eu]: 1,
  [SphereDashboardAPITypes.EServerIdentifier.sphere1]: 2,
};

/** Returns the servers sorted according to the defined server order */
export function sortServers(servers: ActiveServer[]): ActiveServer[] {
  return servers.sort(
    (a, b) => serverOrder[a.identifier] - serverOrder[b.identifier]
  );
}

/** Returns true iff if the passed server status is active and the login provider is defined */
export function isServerActive(
  server: SphereDashboardAPITypes.Server
): server is ServerWithActiveStatus {
  if (!server.status || !server.loginProvider) {
    return false;
  }

  return server.status === SphereDashboardAPITypes.EServerStatus.userFound;
}

/** Maps the backend active server to a ActiveServer entity */
export function getActiveServer(
  server: ServerWithActiveStatus,
  shouldUseDisplayTextVariant: boolean = false
): ActiveServer {
  return {
    identifier: server.identifier,
    text: shouldUseDisplayTextVariant
      ? getUserServerTextVariant(server.identifier)
      : getUserServerText(server.identifier),
    loginProvider: server.loginProvider,
  };
}

/** Returns true iff the list of active servers includes both the Sphere 1 and one of the Sphere 2 servers */
export function haveActiveServersBothSphereVersions(
  activeServersIdentifier: SphereDashboardAPITypes.EServerIdentifier[]
): boolean {
  const isSphere1Server = activeServersIdentifier.includes(
    SphereDashboardAPITypes.EServerIdentifier.sphere1
  );
  const isSphere2EuServer = activeServersIdentifier.includes(
    SphereDashboardAPITypes.EServerIdentifier.sphere2Eu
  );
  const isSphere2UsServer = activeServersIdentifier.includes(
    SphereDashboardAPITypes.EServerIdentifier.sphere2Us
  );
  const isSphere2Server = isSphere2EuServer || isSphere2UsServer;

  return isSphere1Server && isSphere2Server;
}

/** Returns true iff the ULM client is "streamFocus" and the user has 2 active servers: Sphere 1 and one of Sphere 2 */
export function shouldUseSphere1ForStreamApp(
  client: UlmClientType | null,
  activeServers: ActiveServer[] | undefined
): boolean {
  if (client !== UlmClients.streamFocus) {
    return false;
  }

  if (!activeServers || activeServers.length !== 2) {
    return false;
  }

  const activeServersIdentifier = activeServers.map(
    (server) => server.identifier
  );

  return haveActiveServersBothSphereVersions(activeServersIdentifier);
}
