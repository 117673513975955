import { HandledError } from "@context-providers/error-handler/error-handler-types";
import { AnyAction, isAsyncThunkAction } from "@reduxjs/toolkit";
import { fetchEmailServers } from "@store/login/login-slice";

/**
 * @returns HandledError entity based on the rejected thunk
 *
 * @param action reject thunk action
 */
export function createHandledError(action: AnyAction): HandledError {
  let title = "An error occurred";
  let uiType: HandledError["uiType"] = "toast";

  switch (true) {
    case isAsyncThunkAction(fetchEmailServers)(action):
      title = "Failed to check the email";
      uiType = "toast";
      break;
  }

  return {
    id: action.type,
    title,
    error: action.error.message,
    uiType,
  };
}
