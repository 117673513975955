import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { ActiveServer, ServerOption } from "@custom-types/ulm-types";
import { Box, Button } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { colors } from "@styles/common-colors";
import { Checkmark3Icon } from "@faro-lotv/flat-ui";
import USFlagIcon from "@assets/icons/us-flag_24px.svg?react";
import EUFlagIcon from "@assets/icons/eu-flag_24px.svg?react";
import SphereLegacyIcon from "@assets/icons/sphere-legacy.svg?react";
import { ReactNode } from "react";

interface Props {
  /** List of active servers */
  activeServers: ActiveServer[] | ServerOption[];

  /** The selected server */
  selectedServer: SphereDashboardAPITypes.EServerIdentifier | null;

  /** Triggers when user selects a server */
  onSelectServer(server: SphereDashboardAPITypes.EServerIdentifier): void;

  /** Whether to show/hide the label */
  shouldShowLabel?: boolean;

  /** Whether the selector buttons should be disabled */
  isDisabled?: boolean;

  /** Optional component to show on the right side of the label */
  helpComponent?: ReactNode;
}

/** Contains the flags for corresponding server */
export const flagIcons: Record<
  SphereDashboardAPITypes.EServerIdentifier,
  JSX.Element
> = {
  [SphereDashboardAPITypes.EServerIdentifier.sphere2Us]: <USFlagIcon />,
  [SphereDashboardAPITypes.EServerIdentifier.sphere2Eu]: <EUFlagIcon />,
  [SphereDashboardAPITypes.EServerIdentifier.sphere1]: <SphereLegacyIcon />,
};

/** Highest number of possible active servers */
const highestNumberOfServer = 3;

/** Add ability to select server by rendering the active server list */
export function SelectServer({
  activeServers,
  selectedServer,
  onSelectServer,
  helpComponent,
  shouldShowLabel = true,
  isDisabled = false,
}: Props): JSX.Element {
  return (
    <>
      {shouldShowLabel && (
        <FaroInputLabel title="Server" helpComponent={helpComponent} />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {activeServers.map((server) => (
          <Button
            key={server.identifier}
            onClick={() => onSelectServer(server.identifier)}
            disableFocusRipple={true}
            disableRipple={true}
            disableElevation={true}
            disableTouchRipple={true}
            disabled={isDisabled}
            sx={{
              border: `1px solid ${colors.gray500}`,
              borderRadius: "0px",
              minHeight: "39px",
              flexGrow: 1,
              paddingLeft: "10px",
              color:
                selectedServer === server.identifier
                  ? colors.blue500
                  : colors.gray850,
              textAlign: "left",
              justifyContent: "space-between",
              marginBottom: "24px",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: 600,
              "&:first-of-type": {
                // Adjust first element border style
                borderRadius: "4px 0 0 4px",
                borderRight:
                  activeServers.length === highestNumberOfServer ? "none" : "",
              },
              "&:last-of-type": {
                // Adjust last element border style
                borderRadius: "0 4px 4px 0",
                borderLeft: "none",
              },
            }}
            data-testid={`server-${
              selectedServer === server.identifier ? "active" : "inactive"
            }`}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              {flagIcons[server.identifier]}
              {server.text}
            </Box>

            <Checkmark3Icon
              sx={{
                color: colors.blue500,
                visibility:
                  selectedServer === server.identifier ? "visible" : "hidden",
              }}
            />
          </Button>
        ))}
      </Box>
    </>
  );
}
