/**
 * Returns true if provided string is a valid email
 * The validation regex is based on the RFC 5322 email validation standard: https://emailregex.com/
 *
 * @param email Email as string to validate
 * @returns boolean True if email is valid
 */
export function isValidEmail(email: string): boolean {
  const emailValidationRegEx =
    // eslint-disable-next-line max-len -- long validation regex
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailValidationRegEx.test(email);
}
