import { HandledError } from "@context-providers/error-handler/error-handler-types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { errorsAdapter } from "@store/errors/errors-slice";

/**
 * @returns all errors
 */
export const errorsSelector: (state: RootState) => HandledError[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return errorsAdapter.getSelectors().selectAll(state.errors);
    }
  );
