import { Typography, Stack } from "@mui/material";
import { UlmContainer } from "@components/ulm/ulm-container";
import { colors } from "@styles/common-colors";
import PasswordChangedIcon from "@assets/icons/password-changed_60px.svg?react";
import { UlmButton } from "@components/ulm/ulm-button";
import { useNavigate } from "react-router-dom";

/**
 * The PasswordChanged component is used to display a confirmation screen to users
 * after they have successfully changed their password.
 */
export function PasswordChanged(): JSX.Element {
  const navigate = useNavigate();

  return (
    <UlmContainer>
      <Typography
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Password changed
      </Typography>

      <Stack
        sx={{
          width: "106px",
          height: "106px",
          background: colors.gray50,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          marginTop: "25px",
        }}
      >
        <PasswordChangedIcon />
      </Stack>

      <Typography
        sx={{
          color: colors.gray700,
          fontSize: "16px",
          fontWeight: 600,
          marginTop: "25px",
          textAlign: "center",
        }}
      >
        The password for your account has been successfully changed
      </Typography>
      <UlmButton
        buttonText="Continue"
        onClick={() => navigate("/")}
        sx={{
          marginTop: "42px",
        }}
      />
    </UlmContainer>
  );
}
