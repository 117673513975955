import { UlmContainer } from "@components/ulm/ulm-container";
import { Box } from "@mui/material";
import { colors } from "@styles/common-colors";
import FaroWarningIcon from "@assets/icons/warning.svg?react";

/**
 * This is a custom error component which is shown if something goes wrong in routing.
 * e.g. typing a route that is not available.
 */
export function CustomErrorBoundary(): JSX.Element {
  return (
    <UlmContainer>
      <Box
        sx={{
          paddingTop: "150px",
          color: colors.gray850,
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaroWarningIcon
          style={{
            marginRight: "10px",
          }}
        />
        The provided route does not exist
      </Box>
    </UlmContainer>
  );
}
