import { UlmContainer } from "@components/ulm/ulm-container";
import { Box, Button, Typography } from "@mui/material";
import FaroWarningIcon from "@assets/icons/warning.svg?react";
import { HandledError } from "@context-providers/error-handler/error-handler-types";
import { SanitizedHtmlTag } from "@context-providers/error-handler/sanitized-html-tag";
import { getErrorDisplayMarkup } from "@context-providers/error-handler/error-handler-utils";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useCallback } from "react";

interface Props {
  /** The error to be shown in toast */
  error: HandledError;

  /** Callback to close error page */
  close?: () => void;
}

/** Error page with information about the error and action buttons */
export function ErrorPage({ error, close }: Props): JSX.Element {
  const { navigateToLogin } = useAppNavigation();

  /** Closes error page and navigates to login */
  const closeAndGoToLogin = useCallback(() => {
    close?.();
    navigateToLogin();
  }, [close, navigateToLogin]);

  return (
    <UlmContainer>
      <Box
        sx={{
          marginTop: "100px",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        <FaroWarningIcon
          style={{
            marginRight: "10px",
          }}
        />
        {error.title}
      </Box>

      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Developer Error Message:
        </Typography>
        <SanitizedHtmlTag markup={getErrorDisplayMarkup(error.error)} />
      </Box>

      <Box
        sx={{
          marginTop: "40px",
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button onClick={closeAndGoToLogin} variant="outlined">
          Return to Login
        </Button>

        <Button onClick={() => window.location.reload()} variant="outlined">
          Reload
        </Button>
      </Box>
    </UlmContainer>
  );
}
