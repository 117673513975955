import { Stack, Typography, Link, Box } from "@mui/material";
import { UlmContainer } from "@components/ulm/ulm-container";
import { colors } from "@styles/common-colors";
import { UlmButton } from "@components/ulm/ulm-button";
import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { KeyboardEvent, useCallback, useState } from "react";
import { UlmTextField } from "@components/ulm/ulm-text-field";
import { ContactSupport } from "@components/ulm/contact-support";
import ArrowLeftIcon from "@assets/icons/arrow-left_24px.svg?react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { isValidEmail } from "@utils/email-utils";
import { useCoreApiClient } from "@api/core-api/use-core-api-client";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";
import { CheckEmail } from "@pages/ulm/info-messages/check-email";
import { resetFetchedServers } from "@store/login/login-slice";
import { useAppDispatch } from "@store/store-helper";
import { QueryParams } from "@router/route-params";

/**
 * PasswordRecovery component provides a user interface for password recovery.
 * It allows users to enter their email address and request a password reset.
 */
export function PasswordRecovery(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const [searchParams] = useSearchParams();
  const emailValue = searchParams.get(QueryParams.email);

  const dispatch = useAppDispatch();

  const { handleError } = useErrorHandlerContext();

  const [isEmailInputError, setIsEmailInputError] = useState<boolean>(false);
  const [isResendingEmail, setIsResendingEmail] = useState<boolean>(false);
  const [isRecoverPasswordWithSuccess, setIsRecoverPasswordWithSuccess] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>(emailValue ?? "");

  const recoverPassword = useCallback(async () => {
    if (!isValidEmail(email)) {
      setIsEmailInputError(true);
      return;
    }

    setIsResendingEmail(true);

    const emailValue = email.trim();
    const resetPayload = {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by backend
      resetpw_email: emailValue,
    };

    try {
      await coreApiClient.V1.resetUserPassword(resetPayload);
      setIsRecoverPasswordWithSuccess(true);
      setIsResendingEmail(false);
    } catch (error) {
      handleError({
        id: `resetUserPassword-${Date.now().toString()}`,
        title: "Failed to request a password reset.",
        error,
        uiType: "toast",
      });
    }

    setIsEmailInputError(false);
  }, [coreApiClient.V1, email, handleError]);

  const onEmailKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (event.key === "Enter") {
        recoverPassword();
      }
    },
    [recoverPassword]
  );

  if (isRecoverPasswordWithSuccess) {
    return <CheckEmail email={email} />;
  }

  return (
    <UlmContainer>
      <Typography
        sx={{
          marginTop: "100px",
          color: colors.gray850,
          fontSize: "32px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Forgot your password?
      </Typography>

      <Typography
        sx={{
          fontSize: "14px",
          color: colors.gray700,
        }}
      >
        Enter the email address you used during registration.
      </Typography>

      <Stack
        sx={{
          marginTop: "100px",
          width: "100%",
        }}
      >
        <FaroInputLabel title="Email" />
        <UlmTextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          name="email"
          autoComplete="email"
          isError={isEmailInputError}
          errorText={"Enter a valid email address"}
          placeholder={"name@company.com"}
          onKeyUp={onEmailKeyUp}
        />
      </Stack>

      <UlmButton
        buttonText="Reset my password"
        onClick={recoverPassword}
        isLoading={isResendingEmail}
        isLoadingText="Resending..."
        sx={{
          marginTop: "8px",
        }}
      />

      <Link
        component={RouterLink}
        to="/"
        underline="hover"
        sx={{
          color: colors.blue500,
          fontSize: "14px",
          fontWeight: 600,
          alignItems: "center",
          display: "flex",
          marginTop: "52px",
          gap: "10px",
        }}
        onClick={() => {
          dispatch(resetFetchedServers());
        }}
      >
        <ArrowLeftIcon color={colors.blue500} /> Return to log in screen
      </Link>

      <Box
        component="div"
        sx={{
          marginBottom: "10px",
          textAlign: "center",
          fontSize: "14px",
          flex: 1,
          alignContent: "end",
          marginTop: "20px",
        }}
      >
        Can’t log in? Contact <ContactSupport />
      </Box>
    </UlmContainer>
  );
}
