import { FaroInputLabel } from "@components/ulm/faro-input-label";
import { UlmBackButton } from "@components/ulm/ulm-back-button";
import { UlmButton } from "@components/ulm/ulm-button";
import { UlmContainer } from "@components/ulm/ulm-container";
import { Box, TextField } from "@mui/material";
import { colors } from "@styles/common-colors";
import { useEffect, useState } from "react";
import { AcknowledgementText } from "@components/common/acknowledgement-text";
import { CreateAccountContext } from "@pages/ulm/signup/trial-account/create-trial-accounts-types";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { CoreAPITypes } from "@stellar/api-logic";
import { useErrorHandlerContext } from "@context-providers/error-handler/error-handler-context";
import { createTrialUserAccount } from "@pages/ulm/signup/create-account-utils";
import { convertProviderToEnum } from "@components/ulm/SSO-providers/SSO-providers-utils";
import { MainRoutes, QueryParams, SignUpRoutes } from "@router/route-params";
import { ulmConfigClientSelector } from "@store/ulm-config/ulm-config-selector";
import { useAppSelector } from "@store/store-helper";

interface CreateWorkspaceFormErrors {
  /** Error message related to workspace name input field */
  workspaceName: string | null;
}

/** Default error object to set and reset errors state */
const DEFAULT_ERRORS: CreateWorkspaceFormErrors = {
  workspaceName: null,
};

/** Renders create workspace page to add company information */
export function CreateWorkspace(): JSX.Element {
  const { handleError } = useErrorHandlerContext();
  const client = useAppSelector(ulmConfigClientSelector);
  const [searchParams] = useSearchParams();
  const provider = searchParams.get(QueryParams.provider);

  const [errors, setErrors] =
    useState<CreateWorkspaceFormErrors>(DEFAULT_ERRORS);
  const [isCreatingTrialAccount, setIsCreatingTrialAccount] = useState(false);

  const { updateAccountInfo, accountInfo, isSmallHeightScreen, trialType } =
    useOutletContext<CreateAccountContext>();

  useEffect(() => {
    /** Clear password error if not empty  */
    if (accountInfo.workspaceName !== "") {
      setErrors({ ...errors, workspaceName: DEFAULT_ERRORS.workspaceName });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore error object as dependency
  }, [accountInfo.workspaceName]);

  useEffect(() => {
    if (provider) {
      updateAccountInfo({
        previousLocationRef: `/${MainRoutes.signup}/${SignUpRoutes.trial}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  /** Submission of create workspace page, starts creating trial account request process */
  async function onCreateWorkspace(): Promise<void> {
    if (accountInfo.workspaceName === "") {
      setErrors({ ...errors, workspaceName: "Workspace name is required" });
      return;
    }

    setIsCreatingTrialAccount(true);

    let payload: CoreAPITypes.IRegisterTrialPayload;

    if (provider) {
      const providerEnum = convertProviderToEnum(provider);

      payload = {
        provider: providerEnum,
        companyName: accountInfo.workspaceName,
        consents: [],
      };
    } else {
      payload = {
        provider: "email",
        companyName: accountInfo.workspaceName,
        consents: accountInfo.isAlreadySubscribedToNewsLetter
          ? [
              CoreAPITypes.EMailConsent.newsletter,
              CoreAPITypes.EMailConsent.promotional,
            ]
          : [],

        user: {
          email: accountInfo.email,
          password: accountInfo.password,
          firstName: accountInfo.firstName,
          lastName: accountInfo.lastName,
        },
      };

      // Added trialType in the payload if only available
      if (trialType) {
        payload.trialType = trialType;
      }
    }

    try {
      await createTrialUserAccount({
        payload: payload,
        selectedServer: accountInfo.selectedServer,
        client,
      });
    } catch (error) {
      handleError({
        id: `createTrialUserAccount-${Date.now().toString()}`,
        title: "Failed to create trial account.",
        error,
        uiType: "toast",
      });

      setIsCreatingTrialAccount(false);
    }
  }

  return (
    <UlmContainer>
      <Box
        paddingTop={isSmallHeightScreen ? "0" : "50px"}
        sx={{
          color: colors.gray850,
          fontSize: "32px",
          lineHeight: "44px",
          fontWeight: 600,
          letterSpacing: "-0.32px",
          textAlign: "center",
        }}
      >
        Create your workspace
      </Box>
      <Box
        sx={{
          paddingTop: "10px",
          fontSize: "14px",
          lineHeight: "24px",
          color: colors.gray700,
          marginBottom: "40px",
          textAlign: "center",
        }}
      >
        Give your workspace a name to start manage your projects
      </Box>

      {/* Workspace input */}
      <FaroInputLabel title="Workspace name" />
      <TextField
        fullWidth
        value={accountInfo.workspaceName}
        type="text"
        placeholder="For example, company’s or department’s name"
        error={!!errors.workspaceName}
        helperText={errors.workspaceName}
        onChange={({ target }) =>
          updateAccountInfo({ workspaceName: target.value.trim() })
        }
      />

      {/* Create account button */}
      <UlmButton
        buttonText="Get Started"
        onClick={onCreateWorkspace}
        isLoading={isCreatingTrialAccount}
        isLoadingText={"Creating"}
        sx={{
          marginTop: "8px",
        }}
      />

      <UlmBackButton route={accountInfo.previousLocationRef} />

      {/* Terms of use and privacy policy text */}
      <AcknowledgementText />
    </UlmContainer>
  );
}
