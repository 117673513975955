const ERROR_MESSAGES = {
  passwordsDontMatch: "Passwords do not match",
};

export function validateConfirmPasswordError(
  password: string,
  confirmPassword: string
): string | undefined {
  if (password !== confirmPassword) {
    return ERROR_MESSAGES.passwordsDontMatch;
  }

  return undefined;
}
