import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** Interface for a backend server with "user_found" status and loginProvider defined */
export interface ServerWithActiveStatus extends SphereDashboardAPITypes.Server {
  /** User server status */
  status: SphereDashboardAPITypes.EServerStatus.userFound;

  /** Login provider identifier */
  loginProvider: SphereDashboardAPITypes.ELoginProvider;
}

/** Type for the active server entities used in the ULM form control radio buttons */
export type ActiveServer = Pick<
  ServerWithActiveStatus,
  "identifier" | "loginProvider"
> & {
  /** Text to display */
  text: string;
};

/** Type for a server option entity in the server selector for registration */
export type ServerOption = Pick<
  SphereDashboardAPITypes.Server,
  "identifier"
> & {
  /** Text to display */
  text: string;
};

/** List of the text to display for each server */
export enum EServerDisplayText {
  sphere2Us = "US Server",

  sphere2Eu = "EU Server",

  sphere1 = "FARO Sphere",
}

/** Variant of the list of the text to display for each server */
export enum EServerDisplayTextVariant {
  sphere2Us = "Sphere XG (US)",

  sphere2Eu = "Sphere XG (EU)",

  sphere1 = "Sphere Legacy",
}

/** List of supported ULM client types */
export type UlmClientType =
  | "default"
  | "sphereDashboard"
  | "connect"
  | "streamOrbis"
  | "streamFocus"
  | "sphereViewer"
  | "scene";

/** List of supported ULM client config flag types */
export type UlmClientFlagType =
  | "directSignup"
  | "sfSignup"
  | "sphere1Login"
  | "noSphere1Login"
  | "sphere2Login"
  | "noSphere2Login";

/** List of supported registration trial types */
export type UlmTrialType =
  | "cloud-processing-faro-connect"
  | "cloud-processing-gated-trial";

/** Type for the error comes from api response */
export type ApiError = {
  /** Response code */
  code: string;

  /** Message to show user about the error */
  message: string;

  /** Status code of the error */
  status: number;
};
