import { UlmClients } from "@constants/ulm-constants";
import { UlmClientType } from "@custom-types/ulm-types";
import { BrowserUtils } from "@stellar/web-core";
import { requestParentToRedirect } from "@utils/browser-utils";

interface RedirectClientProps {
  /** ULM client */
  client: UlmClientType | null;

  /** redirect URL */
  redirectUrl: string;
}

/**
 * Performs a redirect to the passed URL
 * For some clients the redirect will be carried out in the parent window
 *
 * @param client ULM client. Ex: sphereDashboard, streamOrbis
 * @param redirectUrl URL string to redirect to
 * @throws {Error} if the passed URL string is not a valid URL
 */
export function redirectClient({
  client,
  redirectUrl,
}: RedirectClientProps): void {
  const redirectUrlObject = BrowserUtils.constructUrl({ url: redirectUrl });

  // Throw error if there is no valid URL
  if (!redirectUrlObject) {
    throw new Error("Invalid redirect URL");
  }

  // Sphere Dashboard or Viewer client is a special case since the ULM is embedded in it.
  // Instead of a simple redirect we need to send a window message so they redirect instead
  if (
    client === UlmClients.sphereDashboard ||
    client === UlmClients.sphereViewer
  ) {
    requestParentToRedirect(redirectUrlObject);
  } else {
    BrowserUtils.redirectToUrl(redirectUrlObject.href);
  }
}
