import { Button, ButtonProps, Divider, Stack, Typography } from "@mui/material";
import { colors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { ReactElement } from "react";

export interface Props extends Pick<ButtonProps, "onClick"> {
  /** Text to show inside inside the button */
  label?: string;

  /** Optional sx properties to override the button style. */
  sx?: ButtonProps["sx"];

  /** The provider icon */
  icon?: ReactElement;

  /** The sub title for the provider */
  description?: string;
}

/** Renders an outlined button aligned with proper style */
export function FaroButtonSSO({
  label,
  icon,
  description,
  sx,
  ...rest
}: Props): JSX.Element {
  return (
    <>
      <Button
        data-testid="sso-button-provider"
        variant="outlined"
        title={label}
        disableRipple={true}
        disableElevation={true}
        disableTouchRipple={true}
        sx={{
          ...sx,
          border: "1px solid #0000001A",
          width: "100%",
          padding: "8px 11px",
          color: colors.gray850,
          minHeight: "46px",
        }}
        {...rest}
      >
        {icon && label && (
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", width: "100%" }}
          >
            {icon}
            <Divider orientation="vertical" flexItem sx={{ mx: "10px" }} />
            <Typography
              sx={{
                color: colors.gray850,
                fontSize: DEFAULT_TEXT_FONT_SIZE,
                textTransform: "none",
                textAlign: "center",
                width: "100%",
                fontWeight: 700,
              }}
            >
              {label}
            </Typography>
          </Stack>
        )}
        {icon && !label && icon}
      </Button>
      {description && (
        <Typography
          sx={{
            fontSize: "10px",
            color: colors.gray850,
            mt: "10px",
            textAlign: "center",
          }}
        >
          {description}
        </Typography>
      )}
    </>
  );
}
